import React, { PureComponent } from 'react';
import {Field, reduxForm} from 'redux-form';
import { translate } from 'react-i18next';
import { ToastContainer, ToastStore } from 'react-toasts';
import classnames from 'classnames';
import {
  get_loc_wallets,
  shortAddress,
  getBasePrice,
  formatCount,
  numFormat,
  formatDate,
  getAsset, shortAddr, change24, change7d, getIconConnectProvider, getLockStatus, getTitle, getAPY, getRewardPools,
} from '../../../../modules';
// import BN from '../../../modules/BN';
import LoadingIcon from "mdi-react/LoadingIcon";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Container,
  ButtonGroup,
  Badge,
  Popover,
  PopoverBody,
  Table, Nav, NavItem, NavLink, TabContent, TabPane, Modal
} from 'reactstrap';
import {CopyToClipboard} from "react-copy-to-clipboard";
import { effectsForPools, tradesForPools, operationsForPools, transactionsForPools, streamEffects,  } from '../../../../endpoints/StellarAPI'
import {getAquaRewards, getDepositWithdrawals, getPoolHolders, setPool} from '../../../../endpoints/API'
import {Server} from "../../../../modules/stellar";
import StellarSdk from "stellar-sdk";
import axios from "axios";
import {GLOBE} from "../../../../modules/globeVars";
import ReactApexChart from 'react-apexcharts'
import swal from "sweetalert";
import {Link} from "react-router-dom";
import Footer from "../../../Layout/footer/Footer";
import RingLoader from "react-spinners/RingLoader";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import iconIdent from "../../Account/components/iconIdent";
import Effects from "../../Account/components/Effects/Effects";
import Transactions from "../../Account/components/Effects/Transactions";
import Operations from "../../Account/components/Effects/Operations";
import Trades from "../../Account/components/Effects/Trades";
import DepositedWithdrawals from "./Effects/DepositedWithdrawals";
import moment from 'moment';
import AutorenewIcon from "mdi-react/AutorenewIcon";
import Draggable from "react-draggable";
import Chart from "react-apexcharts";
import {isMobileOnly} from "react-device-detect";
import MenuItem from "@material-ui/core/MenuItem";
import DepositWithdraw from "./DepositWithdraw";
import SpinnerLoader from "../../../../modules/SpinnerLoader";
import PoolHolders from "./PoolHolders";


const ringLoaderCss = css`
  display: inline-block;
  position: absolute;
  top: -16px;
  left: -15px;
`;


const add_color = '#ffc200',
  del_color = '#646777';



class LiquidityPool extends PureComponent {

  constructor() {
    super();

    this.state = {
      modal: false,
      manage_pool_type: 'deposit',
      wallets: [],
      select_account: false,
      stellar: '',
      base_price: '',
      pool: false,
      pool_db: {
        vol_24h: 0,
        fees_24h: 0,
        total_vol_usd: 0,
        amount_a: 0,
        amount_b: 0,
        price_a: 0,
        price_b: 0,
        apr: 0,
        code_a: 'ASSET',
        code_b: 'ASSET',
        issuer_a: "",
        issuer_b: "",
        shares: 0,
        total_vol_xlm: 0,
        trustlines: 0,
        vol_7d: 0,
        vol_xlm_a: 0,
        vol_xlm_b: 0,
        change_24h_a: false,
        change_24h_b: false,
        change_color_24h_a: 'secondary',
        change_color_24h_b: 'secondary',
      },
      pool_stats: [],
      series: [],
      options: {},
      labels: [],
      series_volume: [],
      options_volume: {},
      labels_volume: [],
      series_fees: [],
      options_fees: {},
      labels_fees: [],
      liquidity_loading: true,
      volume_loading: true,
      pool_not_found: false,
      asset_a: {
        image: '/img/digitalcoin.png',
        name: 'Asset Name',
        home_domain: 'asset.domain'
      },
      asset_b: {
        image: '/img/digitalcoin.png',
        name: 'Asset Name',
        home_domain: 'asset.domain'
      },
      first_loading: true,
      activeTab: '1',
      star_color: del_color,
      favorites_pool: false,
      effects: [],
      trades: [],
      operations: [],
      transactions: [],
      effects_more_btn: false,
      trades_more_btn: false,
      operations_more_btn: false,
      transactions_more_btn: false,
      effects_cursor: '',
      trades_cursor: '',
      operations_cursor: '',
      transactions_cursor: '',
      is_locked_a: false,
      is_locked_b: false,
      percent_amm: 0,

      deposits_withdrawals: [],
      cursor_deposits_withdrawals: '',
      deposits_withdrawals_more_btn: false,

      pool_holders: [],
      cursor_pool_holders: '',
      pool_holders_more_btn: false,
    };
  }

  apy_scop = getAPY('pool')
  limit_items = 60
  noTradeHistory = 'Not enough data'

  reward_pools = getRewardPools()


  componentDidMount = () => {

    getTitle(`Stellar Liquidity Pool (AMM)`);

    const pool_id = this.props.match.params.id

    this.getXLMPrice() // -> this.getPoolDB()
    // this.getPool()
    // this.getAllEffects()

    this.checkHash()
    this.checkWatchlist(pool_id)
  }


  getDepositWithdrawals = (pool_id) => {

    const { deposits_withdrawals, cursor_deposits_withdrawals } = this.state;

    this.setState({ load_more: false })

    getDepositWithdrawals(pool_id, this.limit_items, cursor_deposits_withdrawals).then(result => {
      // console.log('result getDepositWithdrawals: ', result)

      if (result.data) {
        const records = result.data._embedded.records;

        if (records.length < this.limit_items || records.length === 0) {
          this.setState({ deposits_withdrawals_more_btn: false });
        } else {
          this.setState({ deposits_withdrawals_more_btn: true });
        }

        const cursor_deposits_withdrawals = records.length ? records[records.length - 1].paging_token : 0

        this.setState({
          deposits_withdrawals: deposits_withdrawals.concat(records),
          cursor_deposits_withdrawals,
          load_more: true,
        })
      }
    })
  }


  getPoolHolders = (pool_id) => {

    const { pool_holders, cursor_pool_holders } = this.state;

    this.setState({ load_more: false })

    getPoolHolders(pool_id, this.limit_items, cursor_pool_holders).then(result => {
      // console.log('result getPoolHolders: ', result)

      if (result.data) {
        const records = result.data._embedded.records;

        if (records.length) {
          if (records.length < this.limit_items || records.length === 0) {
            this.setState({ pool_holders_more_btn: false });
          } else {
            this.setState({ pool_holders_more_btn: true });
          }

          this.setState({
            pool_holders: pool_holders.concat(records),
            cursor_pool_holders: records[records.length - 1].paging_token,
            load_more: true,
          })
        }
      }
    })
  }


  getRewards = async () => {

    const { asset_a, asset_b, pool_db } = this.state

    const response = await getAquaRewards()
    if (response.ok) {
      let json = await response.json();
      // console.log('getAquaRewards json: ', json)

      const rewards = json.filter(pair => pair.asset1 === (pair.asset1 === 'native' ? asset_a.asset_issuer : `${asset_a.asset_code}:${asset_a.asset_issuer}`) && pair.asset2 === `${asset_b.asset_code}:${asset_b.asset_issuer}`)
      const percent_amm = rewards.length ? (rewards[0].sdex_reward_value / pool_db.total_vol_usd * 365).toFixed(2) : 0

      // console.log('rewards: ', rewards)
      // console.log('percent_amm: ', percent_amm)

      this.setState({ percent_amm })
    } else {
      console.log("Error Rewards: " + response.status);
    }
  }


  checkLockedStatus = (issuer, asset_type) => {

    if (issuer === 'native') {
      this.setState({ is_locked_a: 'locked' })
    }
    else {

      Server.loadAccount(issuer)
        .then((sourceAccount) => {

          if (asset_type === 'assetA') {
            this.setState({ is_locked_a: getLockStatus(sourceAccount) ? 'locked' : 'unlocked' })
          }
          if (asset_type === 'assetB') {
            this.setState({ is_locked_b: getLockStatus(sourceAccount) ? 'locked' : 'unlocked' })
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }


  getAllEffects = () => {
    const pool_id = this.props.match.params.id

    this.getEffects(pool_id)
    this.getTrades(pool_id)

    this.getDepositWithdrawals(pool_id)
    // this.getPoolHolders(pool_id)

    this.getOperations(pool_id)
    this.getTransactions(pool_id)

    // this.streamEffects(pool_id)
    // this.streamTrades(pool_id)
    // this.streamOperations(pool_id)
    // this.streamTransactions(pool_id)
  }


  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  }


  // getPoolRemote = (asset_a, asset_b, ) => {
  //
  //   const { pool_db } = this.state
  //
  //   const formData = new FormData();
  //   formData.append('asset_a', asset_a)
  //   formData.append('asset_b', asset_b)
  //
  //   axios.post(`${GLOBE.API_URL}/get_pool`, formData).then(result => {
  //     // console.log('getPoolRemoteSX result: ', result)
  //
  //     if (result.data) {
  //       const pool = JSON.parse(result.data.pool)
  //       const volume30d = result.data.volume30d ? JSON.parse(result.data.volume30d) : []
  //       // if (!Array.isArray(pool.pools)) {
  //       //   // this.setState({ first_loading: false })
  //       //   return
  //       // }
  //       const item = pool.pools[0]
  //       if (!item) return
  //
  //       let assetA = item.reserves[0].asset
  //       let assetB = item.reserves[1].asset
  //       assetA = (assetA !== 'native' ? assetA.split(':') : 'native')
  //       assetB = (assetB !== 'native' ? assetB.split(':') : 'native')
  //
  //       let issuer_a = assetA !== 'native' ? assetA[1] : 'native'
  //       let issuer_b = assetB !== 'native' ? assetB[1] : 'native'
  //
  //       const amount_a = item.reserves[0].amount
  //       const amount_b = item.reserves[1].amount
  //
  //       if (item) {
  //
  //         const pool = {
  //           pool_id: item.id,
  //           amount_a,
  //           code_a: assetA === 'native' ? 'XLM' : assetA[0],
  //           issuer_a: issuer_a,
  //           amount_b,
  //           code_b: assetB === 'native' ? 'XLM' : assetB[0],
  //           issuer_b: issuer_b,
  //           shares: item.total_shares,
  //           trustlines: item.total_trustlines,
  //           fee_bp: item.fee_bp,
  //           total_vol_usd: (item.liquidity ? item.liquidity : 0),
  //           vol_24h: (item.volume_24h ? item.volume_24h : 0),
  //           vol_7d: (item.volume_7d ? item.volume_7d : 0),
  //           fee_24h: (item.fee_24h ? item.fee_24h : 0),
  //           apr: (item.fee_1y ? item.fee_1y : 0).toFixed(2),
  //           volume30d: volume30d.length ? volume30d : [],
  //         }
  //
  //         // console.log('pool: ', pool)
  //
  //         // const pool_db = {...this.state.pool_db, ...pool};
  //
  //         this.setState({
  //           pool_db: {...pool_db, ...pool},
  //           pool_stats: pool.volume30d,
  //         }, () => {
  //           // if (this.state.series.length) return
  //           this.chartTop()
  //           this.getRewards()
  //         })
  //
  //         const formData = new FormData();
  //
  //         formData.append('pool_id', pool.pool_id)
  //         formData.append('amount_a', pool.amount_a)
  //         formData.append('code_a', pool.code_a)
  //         formData.append('issuer_a', pool.issuer_a)
  //         formData.append('amount_b', pool.amount_b)
  //         formData.append('code_b', pool.code_b)
  //         formData.append('issuer_b', pool.issuer_b)
  //         formData.append('shares', pool.shares)
  //         formData.append('trustlines', pool.trustlines)
  //         formData.append('fee_bp', pool.fee_bp)
  //         formData.append('total_vol_usd', pool.total_vol_usd)
  //         formData.append('vol_24h', pool.vol_24h)
  //         formData.append('vol_7d', pool.vol_7d)
  //         formData.append('fee_24h', pool.fee_24h)
  //         formData.append('apr', pool.apr)
  //         formData.append('volume30d', JSON.stringify(pool.volume30d))
  //
  //         this.saveData(formData)
  //       }
  //       else {
  //
  //         this.setState({ pool_not_found: true })
  //
  //         swal({
  //           title: 'Not enough data to update...'
  //         })
  //       }
  //     }
  //
  //     this.hideChartLoaders()
  //   });
  // }


  saveData = (formData) => {

    setPool(formData).then(result => {

      // console.log('set_pool: ', result)

      this.setState({ first_loading: false, })

      if (result.data === 'create_pool') {
        this.getPoolDB()
      }
    })

    // axios.post(`${GLOBE.API_URL}/set_pool`, formData).then(result => {
    //   // console.log('set_pool: ', result)
    //
    //   this.setState({ first_loading: false, })
    //
    //   if (result.data === 'create_pool') {
    //     this.getPoolDB()
    //   }
    //
    // });
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }

    this.toggleHash(tab)
  };


  toggleHash = (tab) => {

    switch(tab) {
      case '1':
        window.location.hash = 'effects';
        break
      case '2':
        window.location.hash = 'trades';
        break
      case '3':
        window.location.hash = 'deposits';
        break
      case '4':
        window.location.hash = 'operations';
        break
      case '5':
        window.location.hash = 'transactions';
        break
      case '6':
        window.location.hash = 'holders';
        break
      default:
        break
    }
  }


  checkHash = () => {
    switch(window.location.hash) {
      case '#effects':
        this.toggle('1');
        break
      case '#trades':
        this.toggle('2');
        break
      case '#deposits':
        this.toggle('3');
        break
      case '#operations':
        this.toggle('4');
        break
      case '#transactions':
        this.toggle('5');
        break
      case '#holders':
        this.toggle('6');
        break
      default:
        break
    }
  }


  streamEffects = (pool_id) => {
    const stream_effects = Server.effects()
      .forLiquidityPool(pool_id)
      .cursor('now')
      .stream({
        onmessage: (effect) => {
          // console.log('effect: ', effect)
          this.setEffectsState([effect, ...this.state.effects])
        }
      });

    this.setState({ stream_effects })
  }

  streamTrades = (pool_id) => {
    const stream_trades = Server.trades()
      .forLiquidityPool(pool_id)
      .cursor('now')
      .stream({
        onmessage: (effect) => {
          // console.log('effect: ', effect)
          this.setTradesState([effect, ...this.state.trades])
        }
      });

    this.setState({ stream_trades })
  }

  streamOperations = (pool_id) => {
    const stream_operations = Server.operations()
      .forLiquidityPool(pool_id)
      .cursor('now')
      .stream({
        onmessage: (effect) => {
          // console.log('effect: ', effect)
          this.setOperationsState([effect, ...this.state.operations])
        }
      });

    this.setState({ stream_operations })
  }

  streamTransactions = (pool_id) => {
    const stream_transactions = Server.transactions()
      .forLiquidityPool(pool_id)
      .cursor('now')
      .stream({
        onmessage: (effect) => {
          // console.log('effect: ', effect)
          this.setTransactionsState([effect, ...this.state.transactions])
        }
      });

    this.setState({ stream_transactions })
  }


  setEffectsState = (effects) => {
    this.setState({ effects })
  }

  setTradesState = (trades) => {
    this.setState({ trades })
  }

  setOperationsState = (operations) => {
    this.setState({ operations })
  }

  setTransactionsState = (transactions) => {
    this.setState({ transactions })
  }


  getEffects = (pool_id) => {

    const { effects_cursor } = this.state

    this.setState({ load_more: false })

    effectsForPools(pool_id, this.limit_items, 'desc', effects_cursor)
      .then( (result) => {
        // console.log('effectsForPools result: ', result);

        if (result.records.length) {
          const effects = this.state.effects.concat(result.records);
          this.setEffectsState(effects)

          if (result.records.length < this.limit_items || result.records.length === 0) {
            this.setState({ effects_more_btn: false });
          } else {
            this.setState({ effects_more_btn: true });
          }

          this.setState({
            effects_cursor: result.records[result.records.length - 1].paging_token,
            load_more: true,
          });

          // this.streamEffects(pool_id)
        }
    })
      .catch( (err) => {
        console.error(err);
      });
  }


  getTrades = (pool_id) => {

    const { trades_cursor } = this.state

    this.setState({ load_more: false })

    tradesForPools(pool_id, this.limit_items, 'desc', trades_cursor)
      .then( (result) => {
        // console.log('tradesForPools result: ', result);

        if (result.records.length) {
          const trades = this.state.trades.concat(result.records);
          this.setTradesState(trades)

          if (result.records.length < this.limit_items || result.records.length === 0) {
            this.setState({ trades_more_btn: false });
          } else {
            this.setState({ trades_more_btn: true });
          }

          this.setState({
            trades_cursor: result.records[result.records.length - 1].paging_token,
            load_more: true,
          });

          // this.streamTrades(pool_id)



          if (!trades_cursor) {
            const formData = new FormData();
            formData.append('pool_id', pool_id)
            formData.append('trades', JSON.stringify(result.records))

            this.saveData(formData)
          }
        }
      })
      .catch( (err) => {
        console.error(err);

        this.getTrades(pool_id)
      });
  }


  getOperations = (pool_id) => {

    const { operations_cursor } = this.state

    this.setState({ load_more: false })

    operationsForPools(pool_id, this.limit_items, 'desc', operations_cursor)
      .then( (result) => {
        // console.log('operationsForPools result: ', result);

        if (result.records.length) {
          const operations = this.state.operations.concat(result.records);
          this.setOperationsState(operations)

          if (result.records.length < this.limit_items || result.records.length === 0) {
            this.setState({ operations_more_btn: false });
          } else {
            this.setState({ operations_more_btn: true });
          }

          this.setState({
            operations_cursor: result.records[result.records.length - 1].paging_token,
            load_more: true,
          });

          // this.streamOperations(pool_id)
        }
      })
      .catch( (err) => {
        console.error(err);
      });
  }


  getTransactions = (pool_id) => {

    const { transactions_cursor } = this.state

    this.setState({ load_more: false })

    transactionsForPools(pool_id, this.limit_items, 'desc', transactions_cursor)
      .then( (result) => {
        // console.log('transactionsForPools result: ', result);

        if (result.records.length) {
          const transactions = this.state.transactions.concat(result.records);
          this.setTransactionsState(transactions)

          if (result.records.length < this.limit_items || result.records.length === 0) {
            this.setState({ transactions_more_btn: false });
          } else {
            this.setState({ transactions_more_btn: true });
          }

          this.setState({
            transactions_cursor: result.records[result.records.length - 1].paging_token,
            load_more: true,
          });

          // this.streamTransactions(pool_id)
        }
      })
      .catch( (err) => {
        console.error(err);
      });
  }



  setOptions = (series, labels, decimels = 0) => {

    // console.log('series: ', series)

    const theme_loc = localStorage.getItem('theme')
    const theme = theme_loc === 'theme-dark' ? 'dark' : 'light'

    const options = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        toolbar: {
          show: false,
        }
      },
      colors:['#03A9F4', '#13D8AA'],
      stroke: {
        width: [0, 2, 5],
        curve: 'smooth'
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },

      grid: {
        show: true,
        borderColor: '#64677726', //#2a2a30
        row: {
          colors: undefined,
          opacity: 0.5
        },
        column: {
          colors: undefined,
          opacity: 0.5
        },
      },

      legend: { show: false },

      theme: {
        mode: theme,
        palette: 'palette1',
        monochrome: {
          enabled: false,
          color: '#255aee',
          // shadeTo: 'light',
          shadeIntensity: 0.65
        },
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        // type: "gradient",
        gradient: {
          inverseColors: false,
          // shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }

      },
      labels: labels // ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003', '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003']
      ,
      markers: {
        size: 0
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: '',
          style: {
            color: '#a5a5a5',
          },
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        fillSeriesColor: false,
        theme: true,
        y: {
          formatter: function (y, x) {

            if (typeof y !== "undefined") {

              if (series[0].name === 'APY') {
                return `${numFormat(y, decimels)}%`
              }
              // else if (series.length > 1 && series[1].name === 'Fee') {
              //   return `$${numFormat(y / 100, 2)}`
              // }
              else {
                return `$${numFormat(y, decimels)}`
              }
            }
            return y;

          }
        }
      },

    }

    return options
  }


  chartTop = () => {

    let pool_stats = this.state.pool_stats ? this.state.pool_stats : this.state.pool_db.volume30d

    const getTime = (item) => {
      let time = new Date(item.time * 1000)
      time.setDate(time.getDate() + 1)
      time = new Date(time.getTime()).toDateString()
      return time
    }


    const liquidity = pool_stats.reverse().map(item => item.liquidity.toFixed(0)) //
    const volume = pool_stats.map(item => item.volume.toFixed(0))
    // const labels = pool_stats.map(item => new Date(item.time * 1000).toDateString())
    const labels = pool_stats.map(item => getTime(item)) // getTime(item) new Date(item.time * 1000).toDateString()
    const aprs = pool_stats.map(item => (item.volume * 0.3 / item.liquidity * 365).toFixed(2))
    const fees = pool_stats.map(item => (item.volume / (0.3 * 1000)).toFixed(2))
    const fees24 = pool_stats.map(item => ((item.volume * 0.3 / item.liquidity * 365) / 365).toFixed(2))

    // const apr = (vol_24h * 0.3 / last_item.liquidity * 356).toFixed(2)

    // const formatFees = (item) => {
    //   const fee = (item.volume * 0.3 / item.liquidity * 356 / (365/24)).toFixed(2)
    //   return Number(isNaN(fee) ? 0 : fee)
    // }
    // const fees = pool_stats.map(item => formatFees(item))
    // const fees_percent = pool_stats.map(item => ((item.volume * 0.3 / item.liquidity * 356) / 365).toFixed(2))
    // const fees24 = pool_stats.map(item => ((item.volume / 100 * formatFees(item)) / (0.3 * 100)).toFixed(0))
    // const fee24 = (item.volume / 100 * formatFees(item))

    const series = [

      {
        name: 'Volume',
        type: 'column',
        data: volume
      },
      {
        name: 'Liquidity',
        type: 'area', // column
        data: liquidity
      },
    ]

    const series_volume = [
      {
        name: 'Volume',
        type: 'column', // column
        data: volume
      },
      {
        name: 'Fee',
        type: 'area', // column
        data: fees, // fees_percent
      },
    ]

    const series_fees = [
      {
        name: 'APY',
        type: 'area', // column
        data: aprs,
      },
      {
        name: 'O/N',
        type: 'area', // column
        data: fees24, // fees_percent
      },
    ]


    this.setState({
      series,
      labels,
      options: this.setOptions(series, labels),

      series_volume,
      options_volume: this.setOptions(series_volume, labels, 2),

      series_fees,
      options_fees: this.setOptions(series_fees, labels, 2),
    })

    this.hideChartLoaders()
  }


  // getVolume = (item) => {
  //
  //   console.log('load stats...')
  //
  //   const formData = new FormData();
  //   formData.append('asset_a', item.reserves[0].asset)
  //   formData.append('asset_b', item.reserves[1].asset)
  //
  //   axios.post(`${GLOBE.API_URL}/get_pool_stats`, formData).then(result => {
  //     if (result.data && Object.keys(result.data).length > 0) {
  //
  //       const pool_stats = result.data
  //       console.log('amm pool_stats: ', pool_stats)
  //
  //       this.setState({
  //           pool_stats,
  //         },
  //         () => {
  //           if (this.state.series.length) return
  //           this.chartTop()
  //           this.hideChartLoaders()
  //         }
  //       )
  //     }
  //     else {
  //
  //       this.hideChartLoaders()
  //
  //       this.setState({ pool_not_found: true })
  //
  //       swal({
  //         title: 'Not enough data to update...'
  //       })
  //     }
  //   });
  // }


  hideChartLoaders = () => {
    this.setState({
      liquidity_loading: false,
      volume_loading: false,
    })
  }


  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)
    if (stellar) {
      this.setState({
          stellar,
          base_price: stellar.market_data.current_price.usd
        },
        () => {
          this.getPoolDB()
        })
    }
  }


  getVolume30d = (history) => {

    // console.log('history: ', history)

    let volume30d = []
    history.forEach((item, index) => {
      if (index < 30) {
        volume30d.push({
          time: item.time,// item.ts,
          volume: item.volume, // (item.volume_value / 10000000),
          liquidity: item.liquidity, // (item.total_value_locked / 10000000),
        })
      }
    })

    this.setState({
        pool_stats: volume30d
      },
      () => this.chartTop()
    )

    return volume30d
  }



  getPoolDB = () => {

    const formData = new FormData();
    formData.append('pool_id', this.props.match.params.id)

    axios.post(`${GLOBE.API_URL}/get_liquidity_pool`, formData).then(result => {
      console.log('getPoolDB result: ', result)
      console.log('parse: ', JSON.parse(result.data.sx_stats_30d))

      let volume30d
      if (result.data.sx_stats_30d) {
        volume30d  = this.getVolume30d(JSON.parse(result.data.sx_stats_30d))
      }


      if (result.data.sx_pool) {

        const sx_pool = result.data.sx_pool.pools[0]
        const pool_db = result.data.pool ? result.data.pool[0] : {}
        const assets = result.data.assets ? result.data.assets : []
        const stats = result.data.sx_block_stats ? result.data.sx_block_stats : {}
        let pool = Object.assign(pool_db, sx_pool, stats)
        // let volume30d = pool_db.volume30d && pool_db.volume30d !== 0 ? JSON.parse(pool_db.volume30d) : []

        let earn_7d = 0, earn_1mo = 0
        if (volume30d) {
          volume30d.map((item, index) => {

            earn_1mo = (earn_1mo + (item.volume * 0.3 / 100))

            if (index < 7) {
              earn_7d = (earn_7d + (item.volume * 0.3 / 100))
            }
          })

          pool_db.earn_7d = earn_7d
          pool_db.earn_1mo = earn_1mo
        }

        const amount_a = sx_pool.reserves[0].amount
        const amount_b = sx_pool.reserves[1].amount

        let asset_a, asset_b

        if (sx_pool.reserves[0].asset === 'native') { // XLM

          const stellar_change = Number(this.state.stellar.market_data.market_cap_change_percentage_24h).toFixed(2)
          const change = Math.sign(stellar_change);

          pool.price_a = this.state.base_price
          pool.change_24h_a = change === 1 ? `+${stellar_change}` : stellar_change
          pool.change_color_24h_a = change === 1 ? 'success' : 'danger'

          asset_a = {
            asset_code: 'XLM',
            asset_issuer: 'native',
            name: 'Stellar',
            image: '/img/logo/stellar-logo.png',
            home_domain: 'stellar.org',
          }
        }
        else {

          asset_a = assets.length ? assets.filter(asset => asset.asset_code === pool.code_a && asset.asset_issuer === pool.issuer_a)[0] : false

          if (asset_a) {
            pool.price_a = asset_a.price
            pool.change_24h_a = asset_a.change24
            pool.change_color_24h_a = asset_a.change_24h_color
          }
          else {

            asset_a = {
              asset_code: sx_pool.reserves[0].asset.split(':')[0],
              asset_issuer: sx_pool.reserves[0].asset.split(':')[1],
              name: '...',
              image: '/img/digitalcoin.png',
              home_domain: '...',
            }
          }
        }

        asset_b = assets.length ? assets.filter(asset => asset.asset_code === pool_db.code_b && asset.asset_issuer === pool_db.issuer_b)[0] : false

        if (asset_b) {
          pool.price_b = asset_b.price
          pool.change_24h_b = asset_b.change24
          pool.change_color_24h_b = asset_b.change_24h_color
        }
        else {

          asset_b = {
            asset_code: sx_pool.reserves[1].asset.split(':')[0],
            asset_issuer: sx_pool.reserves[1].asset.split(':')[1],
            name: '...',
            image: '/img/digitalcoin.png',
            home_domain: '...',
          }
        }

        // console.log('asset_a: ', asset_a)
        // console.log('asset_b: ', asset_b)

        getTitle(`Liquidity Pool: ${asset_a.asset_code}/${asset_b.asset_code} | Stellar AMM`);


        const savePool = {

          pool_id: pool.id,
          amount_a: amount_a, //  / 10000000,
          code_a: asset_a.asset_code,
          issuer_a: asset_a.asset_issuer,
          amount_b: amount_b, //  / 10000000,
          code_b: asset_b.asset_code,
          issuer_b: asset_b.asset_issuer,
          shares: sx_pool.total_shares,
          trustlines: sx_pool.total_trustlines,
          fee_bp: sx_pool.fee_bp,
          total_vol_usd: (pool.liquidity),
          vol_24h: (sx_pool.volume_24h ? sx_pool.volume_24h : 0),
          vol_7d: (sx_pool.volume_7d ? sx_pool.volume_7d : 0),
          fee_24h: (sx_pool.fee_24h ? sx_pool.fee_24h : 0),
          apr: (sx_pool.fee_1y ? sx_pool.fee_1y : 0).toFixed(2),
          volume30d: volume30d && volume30d.length ? volume30d : [],
          home_domain_a: asset_a.home_domain,
          home_domain_b: asset_b.home_domain,
          name_a: asset_a.name,
          name_b: asset_b.name,
          fee_percent: pool.fee_percent,
          liquidity_percent: pool.liquidity_percent,
          volume_percent: pool.volume_percent,
        }


        // console.log('savePool: ', savePool)

        const formData = new FormData();

        formData.append('pool_id', savePool.pool_id)
        formData.append('amount_a', savePool.amount_a)
        formData.append('code_a', savePool.code_a)
        formData.append('issuer_a', savePool.issuer_a)
        formData.append('amount_b', savePool.amount_b)
        formData.append('code_b', savePool.code_b)
        formData.append('issuer_b', savePool.issuer_b)
        formData.append('shares', savePool.shares)
        formData.append('trustlines', savePool.trustlines)
        formData.append('fee_bp', savePool.fee_bp)
        formData.append('total_vol_usd', savePool.total_vol_usd)
        formData.append('vol_24h', savePool.vol_24h)
        formData.append('vol_7d', savePool.vol_7d)
        formData.append('fee_24h', savePool.fee_24h)
        formData.append('apr', savePool.apr)
        formData.append('volume30d', JSON.stringify(savePool.volume30d))
        formData.append('image_a', asset_a.image)
        formData.append('image_b', asset_b.image)
        formData.append('home_domain_a', asset_a.home_domain)
        formData.append('home_domain_b', asset_b.home_domain)
        formData.append('name_a', asset_a.name)
        formData.append('name_b', asset_b.name)
        formData.append('fee_percent', savePool.fee_percent)
        formData.append('liquidity_percent', savePool.liquidity_percent)
        formData.append('volume_percent', savePool.volume_percent)


        this.checkLockedStatus(asset_a.asset_issuer, 'assetA')
        this.checkLockedStatus(asset_b.asset_issuer, 'assetB')

        this.setState({
          pool_db: {...pool_db, ...savePool},
          asset_a,
          asset_b,
          pool,
        },
          () => {
            this.getAllEffects()
            this.getRewards()
            // this.getPool()
            this.saveData(formData)
            this.formatData(pool_db)
          }
        )

        // asset_a = asset_a.asset_issuer === 'native' ? 'native' : `${asset_a.asset_code}:${asset_a.asset_issuer}`
        // asset_b = `${asset_b.asset_code}:${asset_b.asset_issuer}`

        // console.log('volume30d: ', volume30d)
        //
        // if (volume30d === 0) {
        //   this.hideChartLoaders()
        //   // this.getPoolRemote(asset_a, asset_b)
        // }
        // else if (Array.isArray(volume30d)) {
        //
        //   if (volume30d.length) this.hideChartLoaders()
        //
        //   // this.setState({
        //   //     pool_stats: volume30d,
        //   //   },
        //   //   () => {
        //   //     // this.getPoolRemote(asset_a, asset_b)
        //   //     this.chartTop()
        //   //   })
        // }

      }
      else {

        this.getPool()
      }
    });
  }





  // getAssetsDB = (pool_db) => {
  //
  //   const codes = [ pool_db.code_a, pool_db.code_b ]
  //   const issuers = [ pool_db.issuer_a, pool_db.issuer_b ]
  //
  //   const formData = new FormData();
  //   formData.append('asset_codes', codes)
  //   formData.append('asset_issuers', issuers)
  //
  //   axios.post(`${GLOBE.API_URL}/get_pool_assets`, formData).then(result => {
  //     console.log('get_pool_assets result: ', result)
  //
  //     if (result.data.assets.length) {
  //       const assets = result.data.assets
  //       let asset_a = assets.filter(asset => asset.asset_code === pool_db.code_a && asset.asset_issuer === pool_db.issuer_a)[0]
  //       let asset_b = assets.filter(asset => asset.asset_code === pool_db.code_b && asset.asset_issuer === pool_db.issuer_b)[0]
  //
  //       if (!asset_a) {
  //         asset_a = {
  //           asset_code: 'XLM',
  //           asset_issuer: 'native',
  //           name: 'Stellar',
  //           image: '/img/logo/stellar-logo.png',
  //           home_domain: 'stellar.org',
  //         }
  //       }
  //
  //       this.setState({
  //         pool_db,
  //         asset_a,
  //         asset_b,
  //       })
  //     }
  //   });
  // }


  getPool = () => {

    // console.log('load pool...')

    Server
      .liquidityPools()
      .liquidityPoolId(this.props.match.params.id)
      .call()
      .then( (resp) => {
        // console.log('liquidityPool resp: ', resp);

        const assetA = resp.reserves[0].asset === 'native' ? 'XLM' : resp.reserves[0].asset.split(':')[0]
        const assetB = resp.reserves[1].asset.split(':')[0]
        getTitle(`Stellar Liquidity Pool (AMM): ${assetA}/${assetB}`);

        // this.setState({ pool: resp })
        this.formatData(resp)
        // this.getVolume(resp)

        // this.getPoolRemote(resp.reserves[0].asset, resp.reserves[1].asset)
      })
      .catch( (err) => {
        console.error(err);
      });
  }


  formatData = async (item) => {

    let assetA = item.reserves[0].asset
    let assetB = item.reserves[1].asset
    assetA = (assetA !== 'native' ? assetA.split(':') : 'native')
    assetB = (assetB !== 'native' ? assetB.split(':') : 'native')

    let issuer_a = assetA !== 'native' ? assetA[1] : 'native'
    let issuer_b = assetB !== 'native' ? assetB[1] : 'native'

    let vol_xlm_a, vol_xlm_b, price_a, price_b, amount_a, amount_b, price_48_a, price_48_b
    let change_24h_a = 0, change_24h_b = 0
    let change_color_24h_a = 'secondary', change_color_24h_b = 'secondary'

    amount_a = item.reserves[0].amount
    amount_b = item.reserves[1].amount

    const checkFillPair = () => {
      if (vol_xlm_a && vol_xlm_b) {
        setObj()
      }
    }

    const setObj = async () => {

      const total_vol_xlm = (Number(vol_xlm_a) + Number(vol_xlm_b)).toFixed(0)
      const total_vol_usd = (total_vol_xlm * this.state.base_price)

      if (issuer_a === 'native') {
        const stellar_change = Number(this.state.stellar.market_data.market_cap_change_percentage_24h).toFixed(2)
        price_a = Number(this.state.stellar.market_data.current_price.usd).toFixed(2)
        const change = Math.sign(stellar_change);
        change_24h_a = change === 1 ? `+${stellar_change}` : stellar_change
        change_color_24h_a = change === 1 ? 'success' : 'danger'
      }

      const pool = {

        vol_xlm_a,
        vol_xlm_b,
        total_vol_xlm,
        total_vol_usd,
        price_a,
        price_b,
        change_24h_a,
        change_24h_b,
        change_color_24h_a,
        change_color_24h_b,
        price_48_a,
        price_48_b,
      }

      // console.log('pool: ', pool)

      const pool_db = {...this.state.pool_db, ...pool};
      this.setState({ pool_db })

      // return

      const formData = new FormData();
      formData.append('pool_id', item.id)
      formData.append('vol_xlm_a', pool.vol_xlm_a)
      formData.append('vol_xlm_b', pool.vol_xlm_b)
      formData.append('total_vol_xlm', pool.total_vol_xlm)
      formData.append('total_vol_usd', pool.total_vol_usd)
      formData.append('price_a', pool.price_a) // 24h
      formData.append('price_b', pool.price_b)
      formData.append('change_24h_a', pool.change_24h_a)
      formData.append('change_24h_b', pool.change_24h_b)
      formData.append('change_color_24h_a', pool.change_color_24h_a)
      formData.append('change_color_24h_b', pool.change_color_24h_b)
      formData.append('price_48_a', pool.price_48_a) // 7d
      formData.append('price_48_b', pool.price_48_b)

      this.saveData(formData)
    }

    if (assetA !== 'native') {
      const records = await this.getPriceAsset(assetA).then(result => result.records).catch(error => {
        ToastStore.error('Error receiving trades');
      })

      if (records && records[0] && records[1]) {
        const price = records[0]
        price_a = Number((1 / price.avg).toFixed(7))
        vol_xlm_a = (amount_a * price_a).toFixed(7)
        price_48_a = (1 / records[1].avg).toFixed(7)
        change_24h_a = this.change24h(price_a, price_48_a).change24
        change_color_24h_a = this.change24h(price_a, price_48_a).change_24h_color

        checkFillPair()
      }
      else {
        this.showSwalMessage()
      }
    }
    else {

      vol_xlm_a = amount_a
      checkFillPair()
    }

    if (assetB !== 'native') {
      const records = await this.getPriceAsset(assetB).then(result => result.records).catch(error => {
        ToastStore.error('Error receiving trades');
      })

      if (records && records[0] && records[1]) {
        const price = records[0]
        price_b = Number((1 / price.avg).toFixed(7))
        vol_xlm_b = (amount_b * price_b).toFixed(7)
        price_48_b = (1 / records[1].avg).toFixed(7)
        change_24h_b = this.change24h(price_b, price_48_b).change24
        change_color_24h_b = this.change24h(price_b, price_48_b).change_24h_color

        checkFillPair()
      }
      else {
        this.showSwalMessage()
      }
    }
    else {
      vol_xlm_b = amount_b
      checkFillPair()
    }

  }


  showSwalMessage = () => {

    swal({
      title: "No trade history",
      text: `For correct display of data, each asset must have a trade history of at least two days.`,
      icon: "warning",
    })
  }


  change24h = (today, yestoday) => {

    let val = {}

    if (today > yestoday) {
      more();
    }
    else if (today === yestoday) {
      val.change_24h_color = 'secondary';
      val.change24 = '0';
    }
    else {
      less();
    }

    function more() {
      // val.sign = '+';
      val.change24 = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
      val.change_24h_color = 'success';
    }

    function less() {
      val.change24 = Number(100 - yestoday * 100 / today).toFixed(2);
      val.change_24h_color = 'danger';
    }

    return val
  }


  // saveData = (item) => {
  //
  //   const formData = new FormData();
  //
  //   formData.append('pool_id', item.pool_id)
  //   formData.append('amount_a', item.amount_a)
  //   formData.append('code_a', item.code_a)
  //   formData.append('issuer_a', item.issuer_a)
  //   formData.append('amount_b', item.amount_b)
  //   formData.append('code_b', item.code_b)
  //   formData.append('issuer_b', item.issuer_b)
  //   formData.append('shares', item.shares)
  //   formData.append('trustlines', item.trustlines)
  //   formData.append('fee_bp', item.fee_bp)
  //   formData.append('vol_xlm_a', item.vol_xlm_a)
  //   formData.append('vol_xlm_b', item.vol_xlm_b)
  //   formData.append('total_vol_xlm', item.total_vol_xlm)
  //   formData.append('total_vol_usd', item.total_vol_usd)
  //   formData.append('vol_24h', item.vol_24h)
  //   formData.append('vol_7d', item.vol_7d)
  //   formData.append('fee_24h', item.fee_24h)
  //   formData.append('apr', item.apr)
  //   formData.append('volume30d', JSON.stringify(item.volume30d))
  //   formData.append('price_a', item.price_a)
  //   formData.append('price_b', item.price_b)
  //   formData.append('price_48_a', item.price_48_a)
  //   formData.append('price_48_b', item.price_48_b)
  //
  //   axios.post(`${GLOBE.API_URL}/set_pool`, formData).then(result => {
  //     console.log('result: ', result)
  //
  //     if (this.state.first_loading) {
  //       this.getPoolDB()
  //     }
  //
  //     this.setState({ first_loading: false, })
  //
  //   });
  // }


  getPriceAsset = (asset) => {

    const base = new StellarSdk.Asset.native();
    const counter = new StellarSdk.Asset(asset[0], asset[1]);
    const startTime = 0;
    const endTime = 0;
    const resolution = 86400000;
    const offset = 0;

    return Server.tradeAggregation(base, counter, startTime, endTime, resolution, offset)
      .order('desc')
      .limit(2)
      .call()
  }


  checkWatchlist = (pool) => {

    let local_favorites = localStorage.getItem('favorites');

    if (local_favorites) {
      local_favorites = JSON.parse(local_favorites);

      if (local_favorites.pools && local_favorites.pools.some((item) => item === pool)) {
        this.setState({
          star_color: '#ffc200'
        });
      }
    }
  }



  toggleWatchlist = () => {

    const pool = this.state.pool_db.pool_id

    let local_favorites = localStorage.getItem('favorites');

    if (!local_favorites) {

      let favorites = {
        addresses: [],
        assets: [],
        offers: [],
        pools: [],
      }

      favorites.pools.unshift(pool);
      favorites = JSON.stringify(favorites);
      localStorage.setItem('favorites', favorites);

      this.setState({
        favorites_pool: true,
        star_color: '#ffc200'
      });

      ToastStore.success('Pool added to Watchlist');
      // this.setWachAssetDB('set', pool);
    }
    else { // isset local

      local_favorites = JSON.parse(local_favorites);

      let isset_pool = false;
      if (Array.isArray(local_favorites.pools)) {
        isset_pool = local_favorites.pools.some((item) => item === pool);
      }
      else {
        local_favorites.pools = [];
      }

      if (!isset_pool) {
        local_favorites.pools.unshift(pool);
        local_favorites = JSON.stringify(local_favorites);
        localStorage.setItem('favorites', local_favorites);

        this.setState({
          favorites_pool: true,
          star_color: '#ffc200'
        });

        ToastStore.success('Pool added to Watchlist!');
        // this.setWachAssetDB('set', pool);
      }
      else {

        local_favorites.pools.forEach((item, index) => {

          if (item === pool) {
            local_favorites.pools.splice(index, 1);
            local_favorites = JSON.stringify(local_favorites);
            localStorage.setItem('favorites', local_favorites);

            this.setState({
              favorites_pool: false,
              star_color: '#b1c3c8'
            });

            ToastStore.info('Pool removed from Watchlist');
            // this.setWachAssetDB('remove', asset);
          }
        });
      }
    }
  }



  // setWachAssetDB = (type, asset) => {
  //
  //   let auth_user = localStorage.getItem('auth_user');
  //   if (auth_user) {
  //
  //     const formData = new FormData();
  //     formData.append('user_id', JSON.parse(auth_user).user_id)
  //     formData.append('asset_code', asset.asset_code)
  //     formData.append('asset_issuer', asset.asset_issuer)
  //     formData.append('type', type)
  //
  //     axios.post(GLOBE.API_URL+`/watch_asset`, formData)
  //       .then(response => {
  //         // console.log('response: ', response)
  //       });
  //   }
  // }


  managePool = (manage_pool_type) => {
    this.setState({ manage_pool_type })
    this.toggleModal()
  }


  setWallets = (wallets) => {
    this.setState({ wallets })
  }


  selectAccount = (select_account) => {
    this.setState({ select_account })
  }


  renderModal = () => {

    const { modal, manage_pool_type, asset_a, asset_b, pool_db, pool } = this.state;

    let classElem = '', theme = 'theme-light';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = ''
      theme = 'theme-dark'
    }

    return (
      <Modal
        isOpen={modal} //
        toggle={this.toggleModal}
        className={`${theme} modal-dialog--${manage_pool_type === 'Deposit' ? 'success' : 'danger'} modal-dialog--header modal-dialog--swap`}
      >
        <div>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
            <h4 className="bold-text  modal__title">
              {manage_pool_type} {' '}
              <span><span className={'pool-square-sm'}><img src={asset_a.image} alt="" style={{width: 22}}/></span> <span className={'pool-square-sm'}><img src={asset_b.image} alt="" style={{width: 22}}/></span></span>
            </h4>
          </div>
          <div className={`modal__body ${classElem}`}>

            <DepositWithdraw setWallets={this.setWallets}
                             selectAccount={this.selectAccount}
                             {...this.state} />

          </div>
        </div>
      </Modal>
    )
  }




  render() {

    // console.log('this.state: ', this.state)
    // console.log('this.props: ', this.props)

    const { labels, series, pool_db, liquidity_loading, volume_loading, fees_loading, asset_a, asset_b, first_loading,
      activeTab, star_color, stellar, base_price, effects, trades, operations, transactions, pool, is_locked_a, is_locked_b,
      percent_amm, deposits_withdrawals, pool_holders
    } = this.state

    // return <Container className="dashboard"><Card><CardBody>Coming soon...</CardBody></Card></Container>


    return (
      <Container className="dashboard liquidity">
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />


        {
          pool_db.issuer_b ?
            this.renderModal()
          : null
        }

        <Row>
          <Col md={12}>

            {/*<h3 className="page-title">Liquidity Pool*/}
            {/*  /!*<b>{pool_db.code_a}</b>/<b>{pool_db.code_b}</b>*!/*/}

            {/*  <span style={{float: 'right'}}>*/}
            {/*    <Link to={'/pools'} className={'btn btn-secondary btn-sm'}>*/}
            {/*      <i className="fa fa-database"></i>&nbsp; ALL POOLS</Link>*/}
            {/*  </span>*/}
            {/*</h3>*/}
            {/*<h3 className="page-subhead subhead word-break">PoolID: <b>{this.props.match.params.id}</b></h3>*/}

            {/*<span><span className={'pool-square-sm'}><img src={asset_a.image} alt="" style={{width: 26}}/></span> <span className={'pool-square-sm'}><img src={asset_b.image} alt="" style={{width: 26}}/></span></span>*/}

            <h3 className="page-title"
                style={{fontSize: 18}}>
              Pool: {iconIdent(this.props.match.params.id, 'image-id')} {' '}
              <b className={'text-info'}
                 style={{textTransform: 'none'}}>{shortAddr(this.props.match.params.id, 11)}</b> {' '}
               {/*<b className={'text-info'}>{pool_db.code_a} / {pool_db.code_b} </b>{' '}*/}
              <span className="wr-account-btn">
                <CopyToClipboard text={this.props.match.params.id}
                                 onCopy={() => {}}>
                  <a href="#" onClick={() => ToastStore.success('Link copied')}>
                    <span className="sidebar__link-icon lnr lnr-file-empty"
                          style={{color: 'rgb(100, 103, 119)'}}/>
                  </a>
                </CopyToClipboard>

                {/*<a href="#" onClick={this.toggleWatchlist}>*/}
                {/*  <span className="sidebar__link-icon lnr lnr-star"*/}
                {/*        style={{color: star_color}}/>*/}
                {/*</a>*/}



                <small style={{float: 'right'}}><small>Earned</small>: {' '}
                  {
                    pool_db.earn_7d ?
                      <span>7D <b className={'text-info'}>${numFormat(pool_db.earn_7d, 2)}</b> | 30D <b className={'text-info'}>${numFormat(pool_db.earn_1mo, 2)}</b></span>
                    :
                      <i className="fa fa-spinner fa-pulse fa-1x fa-fw text-secondary"></i>
                  }
                </small>



              </span>
            </h3>

          </Col>
        </Row>

        <Row>
          <Col md={4}>

            {/*<Card style={{height: 'auto'}}>*/}
            {/*  <CardBody>*/}
            {/*    <p>Pair</p>*/}
            {/*    <p>Pair</p>*/}
            {/*  </CardBody>*/}
            {/*</Card>*/}

            <Card style={{height: 'auto'}}>
              <CardBody>

                <div className="card__title" style={{marginBottom: 19}}>
                  <h5 className="bold-text">Liquidity Pool</h5>
                </div>
                <div className="panel__btns">
                  {
                    percent_amm ?
                      <div className={'rewards pool-rewards pool-color-aqua'}>Get <b>+{numFormat(pool_db.fee_aqua)}% AQUA</b></div>
                    : null
                  }
                  {
                    this.props.match.params.id === this.reward_pools[0] ||
                    this.props.match.params.id === this.reward_pools[1] ||
                    this.props.match.params.id === this.reward_pools[2] ||
                    this.props.match.params.id === this.reward_pools[3] ||
                    this.props.match.params.id === this.reward_pools[4] ||
                    this.props.match.params.id === this.reward_pools[5] ||
                    this.props.match.params.id === this.reward_pools[6] ||
                    this.props.match.params.id === this.reward_pools[7] ||
                    this.props.match.params.id === this.reward_pools[8] ||
                    this.props.match.params.id === this.reward_pools[9] ||
                    this.props.match.params.id === this.reward_pools[10] ||
                    this.props.match.params.id === this.reward_pools[11] ?
                      <Link to={`/rewards`}><div className={'rewards pool-rewards pool-color-scop'}>Get <b>+{numFormat(this.apy_scop)}% SCOP <i className={'fa fa-question-circle-o'}></i></b></div></Link>
                    : null
                  }

                  <button className="panel__btn text-secondary" type="button" onClick={this.toggleWatchlist}>
                    <i className={'fa fa-star-o'} style={{color: this.state.star_color, fontSize: '15px'}}></i>
                  </button>
                </div>

                {/*<div>*/}
                {/*  {getAsset(asset_a, image_a)} <b className="text-info">{numFormat(item.amount_a, 7)}</b> / <img src={image_b} className={'asset-icon-sm'} alt="" /> {getAsset(asset_b)} <b className="text-info">{numFormat(item.amount_b, 7)}</b>*/}
                {/*</div>*/}

                <hr/>

                <div style={{height: 102}}>

                  { !pool_db.issuer_b ? <div className="panel__refresh" style={{height: '94%'}}><LoadingIcon /></div> : null }

                  <div style={{height: 51}}>
                    <Link to={`/trade/${asset_a.asset_code}-${asset_b.asset_code}/${asset_a.asset_issuer}/${asset_b.asset_issuer}`}>
                      <div className={'icon-asset-pair'}>
                        <img src={asset_a.image} alt=""/>
                      </div>
                    </Link>
                    <div className={'token-name'}>
                      <p className={'token-code'}>{pool_db.code_a}</p> {' '}
                      <div className={'token-name'}>
                        <p><b>{asset_a.name !== '' && asset_a.name !== 'undefined' ? asset_a.name.length > 22 ? shortAddr(asset_a.name, 10) : asset_a.name : pool_db.code_a}</b></p>
                      </div>

                      <div className={'token-domain'}>
                        <small className={'fs-13'}>
                          {/*<i className={'fa fa-globe text-secondary fs-12'}></i> {' '}*/}
                          <a href={`https://${asset_a.home_domain}`}
                             className={'text-secondary'}
                             target={'_blank'}
                             rel='noopener noreferrer'>{asset_a.home_domain}</a> {' '}
                        </small>
                      </div>

                      <div className={'token-issuer'}>
                        <small>
                          {
                            pool_db.issuer_a !== 'native' && asset_a.home_domain === 'stellar.org' ?
                              <span className={'text-danger'}>
                                <b><i className="fa fa-exclamation-triangle"></i> SCAM</b>
                              </span>
                            : null
                          } {' '}
                          <a href={`/account/${pool_db.issuer_a}`}
                             target={'_blank'}
                             className={'text-secondary'}>
                            {
                              pool_db.issuer_a !== 'native' ?
                                <span>
                                  <span className={''}>{ iconIdent(pool_db.issuer_a, 'icon-indent-xs') } {' '}</span>
                                  { shortAddr(pool_db.issuer_a, 4) }
                                </span>
                              :
                                'Native'
                            }
                          </a> {' '}

                          {
                            is_locked_a ?
                              <span>
                                {
                                  is_locked_a === 'locked' ?
                                    <i className={`fa fa-lock text-success fs-12`}></i>
                                    :
                                    <i className={`fa fa-unlock-alt text-secondary fs-12`}></i>
                                }
                              </span>
                            :
                              <i className="fa fa-spinner fa-pulse fa-1x fa-fw text-secondary"></i>
                          }

                        </small>
                      </div>
                    </div>
                  </div>

                  <div style={{height: 51}}>
                    <Link to={`/trade/${asset_b.asset_code}-${asset_a.asset_code}/${asset_b.asset_issuer}/${asset_a.asset_issuer}`}>
                      <div className={'icon-asset-pair'}>
                        <img className={''} src={asset_b.image} alt=""/>
                      </div>
                    </Link>
                    <div className={'token-name'}>
                      <p className={'token-code'}>{pool_db.code_b}</p> {' '}
                      <div className={'token-name'}>
                        <p><b>{asset_b.name !== '' && asset_b.name !== 'undefined' ? asset_b.name.length > 22 ? shortAddr(asset_b.name, 10) : asset_b.name : pool_db.code_b}</b></p>
                      </div>

                      <div className={'token-domain'}>
                        <span className={'fs-13'}>
                          {/*<i className={'fa fa-globe text-secondary fs-12'}></i> {' '}*/}
                          <a href={`https://${asset_b.home_domain}`}
                             className={'text-secondary'}
                             target={'_blank'}
                             rel='noopener noreferrer'>{asset_b.home_domain}</a> {' '}
                        </span>
                      </div>

                      <div className={'token-issuer'}>
                        <small>
                          <a href={`/account/${pool_db.issuer_b}`}
                             target={'_blank'}
                             className={'text-secondary'}>

                            <span>
                              <span className={''}>{ iconIdent(pool_db.issuer_b, 'icon-indent-xs') } {' '}</span>
                              { shortAddr(pool_db.issuer_b, 4) }
                            </span>

                          </a> {' '}

                          {
                            is_locked_b ?
                              <span>
                                {
                                  is_locked_b === 'locked' ?
                                    <i className={`fa fa-lock text-success fs-12`}></i>
                                    :
                                    <i className={`fa fa-unlock-alt text-secondary fs-12`}></i>
                                }
                              </span>
                            :
                              <i className="fa fa-spinner fa-pulse fa-1x fa-fw text-secondary"></i>
                          }
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <hr/>

                <Table className="table--bordered asset-info" responsive>
                  <tbody>


                    <tr>
                      <td>Liquidity</td>
                      <td>$<b className={'text-info'}>{pool_db.total_vol_usd > 1 ? formatCount(pool_db.total_vol_usd, true, 2) : 0.00}</b></td>
                    </tr>

                    <tr>
                      <td>Volume 7D</td>
                      <td>$<b className={'text-info'}>{pool_db.vol_7d > 1 ? formatCount(pool_db.vol_7d, true, 2) : 0.00}</b></td>
                    </tr>

                    <tr>
                      <td>Volume 24H</td>
                      <td>$<b className={'text-info'}>{pool_db.vol_24h > 1 ? formatCount(Number(pool_db.vol_24h).toFixed(7), true, 2) : 0.00}</b></td>
                    </tr>

                    <tr>
                      <td>Fees 24H</td>
                      <td>$<b className={'text-info'}>{numFormat(pool_db.fee_24h, 2)}</b></td>
                    </tr>

                    <tr>
                      <td>APY</td>
                      <td><b className={'text-success'}>+{numFormat(pool_db.apr, 2)}%</b></td>
                    </tr>

                    <tr>
                      <td>Locked <b>{getAsset(asset_a, false, 'secondary')}</b></td>
                      <td><b className={'text-info'}>{pool_db.amount_a < 1 ? numFormat(pool_db.amount_a, 4) : formatCount(pool_db.amount_a, true, 2)}</b></td>
                    </tr>

                    <tr>
                      <td>Locked <b>{getAsset(asset_b, false, 'secondary')}</b></td>
                      <td><b className={'text-info'}>{pool_db.amount_b < 1 ? numFormat(pool_db.amount_b, 4) : formatCount(pool_db.amount_b, true, 2)}</b></td>
                    </tr>

                    <tr>
                      <td>Trustlines</td>
                      <td><b className={'text-info'}>{numFormat(pool_db.trustlines)}</b></td>
                    </tr>

                    <tr>
                      <td>
                        Price: <span><b>{getAsset(asset_a, asset_a.image, 'secondary')}</b> <small>/ { asset_a.asset_issuer === 'native' ? 'USD' : 'XLM' }</small></span> {' '}
                        {/*{*/}
                        {/*  pool_db.price_a ?*/}
                        {/*    <small className={`text-${pool_db.change_color_24h_a}`}>{pool_db.change_24h_a}%</small>*/}
                        {/*  :*/}
                        {/*    <small>Loading...</small>*/}
                        {/*}*/}
                      </td>
                      <td>
                        {
                          asset_a.asset_issuer === 'native' ?
                            <span>
                              <small className={`text-${pool_db.change_color_24h_a}`}>{pool_db.change_24h_a}%</small> {' '}
                              <b className={'text-secondary'}>{numFormat(base_price, 7)}</b>
                            </span>
                            :
                            <span>
                              {
                                pool_db.price_a ?
                                  <span>
                                    <small className={`text-${pool_db.change_color_24h_a}`}>{pool_db.change_24h_a}%</small> {' '}
                                    <b className={'text-secondary'}>{numFormat(pool_db.price_a, 7)}</b>
                                  </span>
                                  :
                                  <i className="fa fa-spinner fa-pulse fa-1x fa-fw text-secondary"></i>
                              }
                            </span>
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>Price: <span><b>{getAsset(asset_b, asset_b.image, 'secondary')}</b> <small>/ XLM</small></span> {' '}
                        {/*{*/}
                        {/*  pool_db.price_b ?*/}
                        {/*    <small className={`text-${pool_db.change_color_24h_b}`}>{pool_db.change_24h_b}%</small>*/}
                        {/*  :*/}
                        {/*    <small>Loading...</small>*/}
                        {/*}*/}
                      </td>
                      <td>
                        {
                          pool_db.price_b ?
                            <span>
                              <small className={`text-${pool_db.change_color_24h_b}`}>{pool_db.change_24h_b}%</small> {' '}
                              <b className={'text-secondary'}>{numFormat(pool_db.price_b, 7)}</b>
                            </span>
                          :
                            <i className="fa fa-spinner fa-pulse fa-1x fa-fw text-secondary"></i>
                        }
                      </td>
                    </tr>

                  </tbody>
                </Table>

              </CardBody>
            </Card>


            <Card style={{height: 'auto'}}>
              <CardBody style={{paddingTop: 37}}>
                <ButtonGroup className="btn-group--justified">
                  <button onClick={() => this.managePool('Deposit')}
                          disabled={pool && pool_db.issuer_b ? false : true}
                          className={'btn btn-success'}>Deposit</button>
                  <button onClick={() => this.managePool('Withdraw')}
                          disabled={pool && pool_db.issuer_b ? false : true}
                          className={'btn btn-danger'}>Withdraw</button>
                </ButtonGroup>
                {/*<ButtonGroup className="btn-group--justified">*/}
                {/*  <Link to={`#`} class={'btn btn-secondary'}>Withdraw</Link>*/}
                {/*</ButtonGroup>*/}
                <br/>
                <ButtonGroup className="btn-group--justified">
                  <Link to={`/swap/${pool_db.code_a}-${pool_db.code_b}/${pool_db.issuer_a}/${pool_db.issuer_b}`}
                        target={'_blank'}
                        className={'btn btn-outline-secondary'} >Swap</Link>
                  <Link to={`/trade/${pool_db.code_b}-${pool_db.code_a}/${pool_db.issuer_b}/${pool_db.issuer_a}`}
                        target={'_blank'}
                        className={'btn btn-outline-secondary'} >Trade</Link>
                </ButtonGroup>
              </CardBody>
            </Card>

          </Col>

          <Col md={8}>

            <Row>

              <Col md={12}>
                <Card style={{height: 'auto'}}>
                  <CardBody style={{height: 504}}>
                    <div className="card__title" style={{marginBottom: 10}}>
                      <h5 className="subhead fs-13">Liquidity (TVL)
                        {
                          first_loading ?
                            <div className={'text-right'}>
                              <span style={{position: "relative"}}>
                                <RingLoader
                                  css={ringLoaderCss}
                                  color={"#70bbfd"}
                                  loading={true}
                                  size={25}
                                />
                              </span>
                            </div>
                            :
                            <small className={'right-pair'}>Pool: <b>{pool_db.code_a}</b>/<b>{pool_db.code_b}</b></small>
                        }

                      </h5>
                      <h3 className="bold-text">{ pool_db.total_vol_usd > 1 ? <span className={'text-lightblue'}>${formatCount(pool_db.total_vol_usd, true, 2)}</span> : '0.00' }</h3>
                      <h5 className="subhead" style={{marginTop: -2}}>{formatDate(new Date())}</h5>
                    </div>

                    { liquidity_loading ? <div className="panel__refresh" style={{height: '94%'}}><LoadingIcon /></div> : null }

                    {
                      series.length ?
                        <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
                      : !liquidity_loading ? <p className={'not-history'}>{this.noTradeHistory}</p> : null
                    }
                  </CardBody>
                </Card>
              </Col>

              <Col md={6}>
                <Card style={{height: 'auto'}}>
                  <CardBody style={{height: 373}}>

                    <div className="card__title" style={{marginBottom: 10}}>
                      <h5 className="subhead">Volume / Fee (24H)
                        <small className={'right-pair'}>Pool: <b>{pool_db.code_a}</b>/<b>{pool_db.code_b}</b></small>
                      </h5>
                      <h4 className="bold-text"> { pool_db.vol_24h > 1 ? <span className={'text-lightblue'}>${formatCount(Number(pool_db.vol_24h), true, 2)}</span> : '0.00' } / { pool_db.fee_24h > 0 ? <span className={'text-lightblue'}>${pool_db.fee_24h > 999 ? formatCount(Number(pool_db.fee_24h), true, 2) : numFormat(pool_db.fee_24h, 2)}</span> : '0.00' }</h4>
                      <h5 className="subhead" style={{marginTop: -2}}><small>{formatDate(new Date())}</small></h5>
                    </div>

                    { volume_loading ? <div className="panel__refresh" style={{height: '92%'}}><LoadingIcon /></div> : null }

                    {
                      series.length ?
                        <ReactApexChart options={this.state.options_volume} series={this.state.series_volume} type="line" height={250} />
                      : !liquidity_loading ? <p className={'not-history'}>{this.noTradeHistory}</p> : null
                    }
                  </CardBody>
                </Card>
              </Col>

              <Col md={6}>
                <Card style={{height: 'auto'}}>
                  <CardBody style={{height: 373}}>
                    <div className="card__title" style={{marginBottom: 10}}>
                      <h5 className="subhead">APY 30d
                        {/* / O/N*/}
                        <small className={'right-pair'}>Pool: <b>{pool_db.code_a}</b>/<b>{pool_db.code_b}</b></small>
                      </h5>
                      <h4 className="bold-text text-success"> { pool_db.fee_24h > 0 ? <span>+{numFormat(pool_db.apr, 2)}</span> : '0.00' }% <small className={'text-secondary'}></small></h4>
                      {/*<small>{numFormat(pool_db.apr / 365, 2)}%</small>*/}
                      <h5 className="subhead" style={{marginTop: -2}}><small>Average over the last 30 days</small></h5>
                    </div>

                    { volume_loading ? <div className="panel__refresh" style={{height: '92%'}}><LoadingIcon /></div> : null }

                    {
                      series.length ?
                        <ReactApexChart options={this.state.options_fees} series={this.state.series_fees} type="line" height={250} />
                      : !liquidity_loading ? <p className={'not-history'}>{this.noTradeHistory}</p> : null
                    }
                  </CardBody>
                </Card>
              </Col>

            </Row>

          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <CardBody>

                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => this.toggle('1')}
                        >
                          <b>Effects</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '2' })}
                          onClick={() => this.toggle('2')}
                        >
                          <b>Trades</b>
                        </NavLink>
                      </NavItem>
                      {/*<NavItem>*/}
                      {/*  <NavLink*/}
                      {/*    className={classnames({ active: activeTab === '3' })}*/}
                      {/*    onClick={() => this.toggle('3')}*/}
                      {/*  >*/}
                      {/*    <b>Deposits</b>*/}
                      {/*  </NavLink>*/}
                      {/*</NavItem>*/}
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '4' })}
                          onClick={() => this.toggle('4')}
                        >
                          <b>Operations</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '5' })}
                          onClick={() => this.toggle('5')}
                        >
                          <b>Transactions</b>
                        </NavLink>
                      </NavItem>
                      {/*<NavItem>*/}
                      {/*  <NavLink*/}
                      {/*    className={classnames({ active: activeTab === '6' })}*/}
                      {/*    onClick={() => this.toggle('6')}*/}
                      {/*  >*/}
                      {/*    <b>Holders</b>*/}
                      {/*  </NavLink>*/}
                      {/*</NavItem>*/}
                    </Nav>


                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        { effects.length ? <Effects items={effects} /> : <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.effects_more_btn ?
                              <Button color="secondary" onClick={() => this.getEffects(pool_db.pool_id)}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                    <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        { trades.length ? <Trades items={trades} /> : <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.trades_more_btn ?
                              <Button color="secondary" onClick={() => this.getTrades(pool_db.pool_id)}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                    <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>

                      <TabPane tabId="3">
                        {
                          deposits_withdrawals.length ?
                            <DepositedWithdrawals {...this.state} />
                          :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.deposits_withdrawals_more_btn ?
                              <Button color="secondary" onClick={() => this.getDepositWithdrawals(pool_db.pool_id)}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                    <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>

                      <TabPane tabId="4">
                        { operations.length ? <Operations items={operations} /> : <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.operations_more_btn ?
                              <Button color="secondary" onClick={() => this.getOperations(pool_db.pool_id)}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                    <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>
                      <TabPane tabId="5">
                        { transactions.length ? <Transactions items={transactions} /> : <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.transations_more_btn ?
                              <Button color="secondary" onClick={() => this.getTransactions(pool_db.pool_id)}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                    <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>

                      <TabPane tabId="6">
                        {
                          pool_holders.length && pool_db.price_a && pool_db.price_b ?
                            <PoolHolders {...this.state} />
                            :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.pool_holders_more_btn ?
                              <Button color="secondary" onClick={() => this.getPoolHolders(pool_db.pool_id)}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                    <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>
                    </TabContent>

                  </div>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    )
  }
}



export default reduxForm({
  form: 'WrapClaimBalances', // a unique identifier for this form
})(translate('common')(LiquidityPool));
