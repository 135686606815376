import React, { PureComponent, useState } from 'react';
import {
  Col,
  Container,
  Row,
  Progress,
  Card,
  CardBody,
  Button,
  ButtonGroup,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ButtonToolbar,
  FormGroup,
  Input,
  Label,
  Table,
  Badge,
  UncontrolledPopover,
  Popover,
  PopoverBody,
  PopoverHeader,
  Toast,
  ToastBody,
  ToastHeader,
  UncontrolledTooltip,
  UncontrolledCarousel
} from 'reactstrap';
import { translate } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../../Layout/footer/Footer';
import classnames from 'classnames';
import { GLOBE } from '../../../modules/globeVars/index';
import axios from 'axios';
import {
  showErrorMessage, shortAddr, numFormat, get_loc_wallets, minBalance,
  baseReserve, getAuth, needDeauthAll, setPairToLocal, checkAuth, formatDate,
  getNameAddr, shortAddress, checkConnectProvider, getIconConnectProvider,
  getTitle, signConnectProvider, getLockStatus, parseNumber, getAPY, getSCOPAsset, getStellarFee, fetchUrl, change7d
} from '../../../modules/index';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { format, render, cancel, register } from 'timeago.js';
import swal from 'sweetalert';
import { ToastContainer, ToastStore } from 'react-toasts';
import AcceptAsset from "../Assets/components/AcceptAsset";
import moment from 'moment';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CSSTransitionGroup } from 'react-transition-group' // ES6
// import renderSelectField from '../../../shared/components/form/Select';
// import { withTranslation } from 'react-i18next';
import AssetChart from '../Asset/components/AssetChart';
import iconIdent from '../Account/components/iconIdent';
// import NotificationSystem from 'rc-notification';
import NotificationSystem from 'react-notification-system';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SpinnerLoader from '../../../modules/SpinnerLoader/index';
// import ResultCodes from '../../../endpoints/ResultCodes'
import Trades from '../Account/components/Effects/Trades';
import PaymentsForAsset from '../Asset/components/PaymentsForAsset';
import Trustlines from '../Asset/components/Trustlines';
import AssetOffers from '../Asset/components/AssetOffers';
import Markets from '../Asset/components/Markets';
import Holders from '../Asset/components/Holders';
import Issuer from '../Asset/components/Issuer';
import Offers from '../Account/components/Effects/Offers';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import Draggable from 'react-draggable';
import {
  getAssetOffers,
  getChAsset,
  getMarketAssets,
  getMarkets,
  getTrustlines,
  saveTradeToDB
} from "../../../endpoints/API";
import getPrice from "../../App/GetPrice";
import Chart from "react-apexcharts";
import { isMobileOnly } from "react-device-detect";



const override = css`
  display: inline-block;
  margin-bottom: -2px;
`;


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};




const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

let page_count = 0;
let limit_items = 60;


const getCounterAsset = (item) => {
  return (
    <a href={'/asset/'+(item.counter_asset_type === 'native' ? 'XLM-native' : item.counter_asset_code + '-' + item.counter_asset_issuer)}>
      <b className="text-warning">{ item.counter_asset_type === 'native' ? 'XLM' : item.counter_asset_code }</b>
    </a>
    //   <a href={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}
    //          {'/asset/'+(item.counter_asset_type === 'native' ? 'XLM-native' : item.counter_asset_code + '-' + item.counter_asset_issuer)}>
    //     <b className="text-warning">{ item.counter_asset_type === 'native' ? 'XLM' : item.counter_asset_code }</b>
    //   </a>
    )
}


const getBaseAsset = (item) => {
  return (
    <a href={'/asset/'+(item.base_asset_type === 'native' ? 'XLM-native' : item.base_asset_code + '-' + item.base_asset_issuer)}>
      <b className="text-warning">{ item.base_asset_type === 'native' ? 'XLM' : item.base_asset_code }</b>
    </a>
  )
}



class Trade extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      // payments: false,
      account: false,
      modal: false,
      modalRating: false,
      activeTabAssets: '1',
      theme: localStorage.getItem('theme'),
      wallets: get_loc_wallets(),
      asset: false,
      base_volume: 0,
      // usd_volume: 0,
      counter_volume: 0,
      activeTab: '1',
      activeTabLeft: '1',
      assets_db: [],
      load_more: true,
      load_assets: false,

      select_addresses: false,
      signer: '',
      counter_asset: {
        asset_code: '',
        asset_issuer: ''
      },
      base_asset: {
        asset_code: 'XLM',
        asset_issuer: 'native'
      },
      counter_asset_db: false,
      counter_image: '/img/digitalcoin.png',
      counter_name: 'Asset Name',
      counter_domain: 'asset.domain',
      base_image: '/img/digitalcoin.png',
      base_name: 'Asset Name',
      base_domain: 'asset.domain',

      native_asset: {
        asset_code: 'XLM',
        asset_issuer: 'native',
        name: 'Stellar',
        image: '/img/logo/stellar-logo-gray.png',
        home_domain: 'stellar.org',
      },

      load_toml: false,
      orderbook: false,
      orderbook_limit: 200,
      maxDepthBuy: 0,
      maxDepthSell: 0,
      buy_counter_amount: 0,
      sell_counter_amount: 0,

      buy_price_orderbook: 0,
      sell_price_orderbook: 0,
      orig_buy_price_orderbook: 0,
      orig_sell_price_orderbook: 0,
      buy_amount_orderbook: 0,
      sell_total_orderbook: 0,
      buy_total_orderbook: 0,
      sell_amount_orderbook: 0,

      stream_orderbook: false,

      trades: [],
      isTrades: true,
      stream_trades: false,
      cursor_trades: false,
      trades_more_btn: false,

      my_trades: [],
      stream_my_trades: false,
      cursor_my_trades: false,
      my_trades_more_btn: false,

      payments: [],
      cursor_payments: false,
      payments_more_btn: false,

      holders: [],
      cursor_holders: false,
      holders_more_btn: false,

      trustlines: [],
      cursor_trustlines: '',
      trustlines_more_btn: false,

      asset_offers: [],
      cursor_asset_offers: '',
      asset_offers_more_btn: false,

      markets: [],
      cursor_markets: '',
      markets_more_btn: false,
      assets: [],

      offers: [],
      load_offers: false,
      cursor_offers: false,
      offers_more_btn: false,

      stream_account: false,
      trades_all: false,
      trades_aggregate: false,
      isTradesAggregate: true,
      refresh_trades_aggregate: false,
      stop_chart: false,
      counter_price_0d: 0.00,
      counter_change1d_color: 'secondary',
      refresh:false,
      counter_base_price: 0,
      counter_usd_price: 0,
      price: false,
      counter_change1d: '...',
      counter_change7d: '...',
      select_address: false,
      loading_account: false,
      redirect_wallet: false,

      native_balance: 0,
      counter_balance: 0,
      reserve_balance: 0,

      buy_sell_loader: false,
      popoverOpen: false,
      popoverQueryOpen: false,
      popoverCapOpen: false,
      popover_id: false,
      showOfferToast: true,
      offerTxResult: false,

      price_low: 0,
      price_high: 0,
      price_open: 0,
      price_close: 0,
      price_avg: 0,
      spread: 0,
      locked: false,
      toml: false,
      trust_counter: false,
      trust_base: false,
      counter_xlm_price_for_cap: 0,
      base_xlm_price_for_cap: 0,
      up_token: false,
      search_asset_code: false,
      stellar_assets: false,
      loading_stellar_assets: false,
      exchange_pairs: localStorage.getItem('pairs') ? JSON.parse(localStorage.getItem('pairs')).reverse() : false,
      show_accept_modal: false,
      asset_chart_show: false,
      blocked_domain: false,
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  trade_aggregation_limit = 200;
  count = 0;
  tryAggregateCnt = 0
  noTradeHistory = 'No trade history'

  scop = getSCOPAsset()
  apy_scop = getAPY('pool')
  fee = getStellarFee()


  componentDidMount() {
    getTitle(`Asset Trading on Stellar DEX`);


    this.getUrl(false)
    register('en_US', this.locale);
    // this.streamAccount()
    // this.streamTradeAgregation()
    this.getAccountsBalances()
    this.getBasePrice()
    this.checkLastAccount()
    this.checkHash()


  }



  checkHash = () => {
    switch(window.location.hash) {
      case '#trdes':
        this.toggle('1');
        break
      case '#payments':
        this.toggle('2');
        break
      case '#trustlines':
        this.toggle('3');
        break
      case '#offers':
        this.toggle('4');
        break
      case '#markets':
        this.toggle('5');
        break
      case '#holders':
        this.toggle('6');
        break
      case '#issuer':
        this.toggle('7');
        break
      case '#my-offers':
        this.toggle('8');
        break
      case '#my-trades':
        this.toggle('9');
        break
      case '#pairs':
        this.toggle('10');
        break
      default:
        break
    }
  }


  togglePopover = (item, index) => {

    if (item && item.price) {
      this.setState(prevState => ({
        popover_trade: item,
        popover_price: numFormat((item.price.d / item.price.n), 7),
        popover_id: index,
        popoverOpen: !prevState.popoverOpen
      }));
    }
  }


  togglePopoverQuery = () => {
    this.setState(prevState => ({
      popoverQueryOpen: !prevState.popoverQueryOpen
    }));
  }

  togglePopoverCap = () => {
    this.setState(prevState => ({
      popoverCapOpen: !prevState.popoverCapOpen
    }));
  }

  togglePopoverBlockDomain = () => {
    this.setState(prevState => ({
      popoverBlockOpen: !prevState.popoverBlockOpen
    }));
  }


  toggleTabLeft(tab) {
    if (this.state.activeTabLeft !== tab) {
      this.setState({
        activeTabLeft: tab
      });
    }
  }


  toggleAssets = (tab) => {
    if (this.state.activeTabAssets !== tab) {
      this.setState({
        activeTabAssets: tab,
      });
    }
  };


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }

    this.toggleHash(tab)
  };


  toggleHash = (tab) => {

    switch(tab) {
      case '1':
        window.location.hash = 'trades';
        break
      case '2':
        window.location.hash = 'payments';
        break
      case '3':
        window.location.hash = 'trustlines';
        break
      case '4':
        window.location.hash = 'offers';
        break
      case '5':
        window.location.hash = 'markets';
        break
      case '6':
        window.location.hash = 'holders';
        break
      case '7':
        window.location.hash = 'issuer';
        break
      case '8':
        window.location.hash = 'my-offers';
        break
      case '9':
        window.location.hash = 'my-trades';
        break
      case '10':
        window.location.hash = 'pairs';
        break
      default:
        break
    }
  }



  checkLastAccount = () => {
    const select_address = localStorage.getItem('select_address');
    const { wallets } = this.state;

    if (wallets && select_address) {

      const isMySelectAddress = wallets.some(item => item.pk === select_address)

      if (isMySelectAddress) {
        this.props.change('select_address', select_address);

        this.setState({ account: false, select_address })

        this.getAccount(select_address, false);
        this.getSigner(select_address);
        this.getTradesForAccount(select_address)
      }
    }
  }


  getBasePrice() {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({
        price: price.crypto_price.xlm_usd,
        changeColor: Number(price.crypto_price.usd.percent_change_24h) < 0 ? 'danger' : 'success',
        change: price.crypto_price.usd.percent_change_24h,
      });
    }
    else if (price && price.local_price) {
      this.setState({
        price: price.local_price.USD,
      })
    }
    else {
      if (this.count < 2) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }



  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }


  toggleModalRating = () => {
    this.setState({
      modalRating: !this.state.modalRating,
    });
  }


  toggleAcceptModal = (value) => {
    if (value) {
      this.setState({ show_accept_modal: true })
      return
    }
    setTimeout(() => this.setState({ show_accept_modal: !this.state.show_accept_modal }), 1000)
  }


  getOffers(address) {

    Server.offers()
      .forAccount(address)
      .limit(200)
      .order('desc')
      .call()
      .then((offers) => {
        // console.log('getOffers offers: ', offers)

        this.setState({ offers: [] }, () => {
          this.setState({
            offers: offers.records,
            load_offers: true
          })
        })
      })
      .catch( (err) => {
        console.error(err);
      })
  }



  notificationSystem = React.createRef();

  addNotification = (result, type) => {

    const offerResults = result.offerResults[0];
    let effect = offerResults.effect;
    let title, subtitle, children, sell_amount, buy_amount, price, currentOffer, status;

    const amountBought = offerResults.amountBought;
    const amountSold = offerResults.amountSold;

    if (offerResults.isFullyOpen) {
      title = 'Offer Created';
      status = 'info';
    } else {
      title = 'Offer Completed!';
      status = 'success';
    }

    if (offerResults.wasImmediatelyDeleted) {
      subtitle = 'Offer was immediately deleted';
    } else if (offerResults.wasImmediatelyFilled) {
      subtitle = 'Offer was immediately filled';
    } else if (offerResults.wasPartiallyFilled) {
      subtitle = 'Offer was partially filled';
    } else {
      subtitle = 'Offer was placed';
    }


    if (effect === 'manageOfferCreated') {

      currentOffer = offerResults.currentOffer;

      if (type === 'buy') {
        price = (currentOffer.price.d / currentOffer.price.n);
        sell_amount = currentOffer.amount;
        buy_amount = (sell_amount / price).toFixed(7)
      }
      if (type === 'sell') {
        price = (currentOffer.price.n / currentOffer.price.d);
        sell_amount = currentOffer.amount;
        buy_amount = (sell_amount * price).toFixed(7)
      }

      children = (
        <div className="body-notif">
          <div className={'subtite-offer text-secondary'}>
            <small>{subtitle}<span className="type-offer">Type: <b className={`text-${type === 'buy' ? 'success' : 'danger'}`}>{type}</b></span></small>
          </div>
          {
            offerResults.offersClaimed.length ?
              type === 'buy' ?
                <div className="result-amount">Bought: <b className="text-info">{numFormat(amountBought, 7)}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b>, Sold: <b className="text-info">{numFormat(amountSold, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b></div>
                :
                <div className="result-amount">Sold: <b className="text-info">{numFormat(amountSold, 7)}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b>, Bought: <b className="text-info">{numFormat(amountBought, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b></div>
              : null
          }
          {
            type === 'buy' ?
              <div className="item-offer">{shortAddress(result.source_account)} <span className={`text-success`}>Buys</span> <b className="text-info">{numFormat(buy_amount, 7)}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b> / <span className={`text-danger`}>Sells</span> <b className="text-info">{numFormat(sell_amount, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b> | Offer:  <Link to={`/offer/${currentOffer.offerId}`}><b>{currentOffer.offerId}</b></Link></div>
              :
              <div className="item-offer">{shortAddress(result.source_account)} <span className={`text-danger`}>Sells</span> <b className="text-info">{numFormat(sell_amount, 7)}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b> / <span className={`text-success`}>Buys</span> <b className="text-info">{numFormat(buy_amount, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b> | Offer:  <Link to={`/offer/${currentOffer.offerId}`}><b>{currentOffer.offerId}</b></Link></div>
          }


          { offerResults.offersClaimed.length ? (
            <div><hr/><div className="offer-added">Filled offers:</div></div>
            ) : null }

          { offerResults.offersClaimed.length ?
              offerResults.offersClaimed.map(item => (
                <div key={item.offerId} className="item-offer">{shortAddress(result.source_account)} <span className={`text-danger`}>{type === 'sell'}sold</span> <b className="text-info">{numFormat(item.amountBought, 7)}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b> / <span className={`text-success`}>bought</span> <b className="text-info">{numFormat(item.amountSold, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b> to {shortAddress(item.sellerId)} <br/> | Offer: <Link to={`/offer/${item.offerId}`}><b>{item.offerId}</b></Link></div>
              ))
              : null }

          <div>
            <div className="bottom-line">Price: <b className={`text-${type === 'buy' ? 'success' : 'danger'}`}>{numFormat(price, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b>
              <span style={{float: 'right'}}>{formatDate(result.created_at)}</span>
            </div>
          </div>
        </div>
      )
    }


    if (effect === 'manageOfferDeleted') {
      status = 'success';

      if (type === 'buy') {
        price = (amountSold / amountBought).toFixed(7)
      }
      if (type === 'sell') {
        price = (amountBought / amountSold).toFixed(7)
      }

      if (offerResults.currentOffer) {
        const currentOffer = offerResults.currentOffer;

        if (type === 'buy') {
          sell_amount = currentOffer.amount;
          buy_amount = (sell_amount / price).toFixed(7)
        }
        if (type === 'sell') {
          buy_amount = currentOffer.amount;
          sell_amount = (buy_amount / price).toFixed(7)
        }
      }

      children = (
        <div className="body-notif">
          <div className={'subtite-offer text-secondary'}>
            <small>{subtitle}<span className="type-offer">Type: <b className={`text-${type === 'buy' ? 'success' : 'danger'}`}>{type}</b></span></small>
          </div>
          {
            type === 'buy' ?
              <div className="result-amount">Bought: <b className="text-info">{amountBought}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b>, Sold: <b className="text-info">{amountSold}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b></div>
              :
              <div className="result-amount">Sold: <b className="text-info">{amountSold}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b>, Bought: <b className="text-info">{amountBought}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b></div>
          }

          { offerResults.offersClaimed.length ?
            offerResults.offersClaimed.map(item => (
              <div key={item.offerId} className="item-offer">{shortAddress(result.source_account)} <span className={`text-success`}>bought</span> <b className="text-info">{numFormat(item.amountSold, 7)}</b> <b className="text-warning">{type === 'buy' ? this.state.counter_asset.asset_code : this.state.base_asset.asset_code}</b> / <span className={`text-danger`}>sold</span> <b className="text-info">{numFormat(item.amountBought, 7)}</b> <b className="text-warning">{type === 'buy' ? this.state.base_asset.asset_code : this.state.counter_asset.asset_code}</b> to {shortAddress(item.sellerId)} <br/> | Offer: <Link to={`/offer/${item.offerId}`}><b>{item.offerId}</b></Link></div>
            ))
            : null }

          { offerResults.currentOffer ?
            <div>
              <hr/>
              <div className="offer-added">+ Offer Added:</div>
              <div className="item-offer">{shortAddress(result.source_account)} <span className={`text-success`}>Buys</span> <b className="text-info">{buy_amount}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b> / <span className={`text-danger`}>Sells</span> <b className="text-info">{sell_amount}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b> Price: <b>{price}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b> <br/> | Offer: <Link to={`/offer/${currentOffer.offerId}`}><b>{currentOffer.offerId}</b></Link></div>
            </div>
            : null }
          <div>
              <span className="bottom-line">Price: <b className={`text-${type === 'buy' ? 'success' : 'danger'}`}>{numFormat(price, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b>
                <span style={{float: 'right'}}>{formatDate(result.created_at)}</span>
              </span>
          </div>
        </div>
      )
    }


    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: `${title}`,
      // message: 'Notification message, Notification message, Notification message',
      level: status,
      position: 'bc',
      autoDismiss: 500,
      children: (children)
    });
  };


  checkMyAccount = (address) => {
    const wallets = this.state.wallets;
    let check = false;

    if (wallets) {
      wallets.forEach((item, index) => {
        if (item.pk === address) {
          check = true;
        }
      })
    }

    return check;
  }



  getAccountsBalances() {

    const getItemAccount = (item, index) => {
      // var item = item;
      item.id = index;
      let wallets_arr = []

      Server.loadAccount(item.pk)
        .then((account) => {

          item.account = account;
          item.balance = numFormat(account.balances[account.balances.length-1].balance, 2);
          item.reserve = (account.subentry_count * baseReserve());
          item.avaliable = (item.balance - item.reserve).toFixed(2);

          wallets_arr.push(item);
          setNewState(wallets_arr)
        })
        .catch((err) => {
          console.error(err);

          wallets_arr.push(item)
          setNewState(wallets_arr)
      })

      const setNewState = (wallets_arr) => {
        if (this.state.wallets.length === wallets_arr.length) {
          newState();
          // this.checkLastAccount()
        }
      }

      const newState = () => {
        this.setState({
          select_addresses: wallets_arr,
        })
      }
    }

    if (this.state.wallets) {
      this.state.wallets.forEach((item, index) => {
        getItemAccount(item, index);
      })
    }
  }


  streamAccount(address) {

    const stream_account = Server.accounts()
      .accountId(address)
      .cursor('now')
      .stream({
        onmessage: (message) => {
          // console.log('message Account: ', message)
        }
      });

    // this.setState({
    //   stream_account
    // })
  }


  locale = (number, index, totalSec) => {
    // number: the time ago / time in number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
      ['now', 'now'],
      ['%s sec', '%s sec'],
      ['1 min', '1 min'],
      ['%s min', '%s min'],
      ['1 hour', '1 hour'],
      ['%s hours', '%s hours'],
      ['1 day', '1 day'],
      ['%s days', '%s days'],
      ['1 week', '1 week'],
      ['%s weeks', '%s weeks'],
      ['1 month', '1 month'],
      ['%s months', '%s months'],
      ['1 year', '1 year'],
      ['%s years', '%s years']
    ][index];
  };


getSigner(address) {

    const wallets = this.state.wallets;

    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === address;
      });

      this.setState({
        signer: signer,
      })
    }
}


// getXlmPrice() {
//   const price = JSON.parse(localStorage.getItem('price'));
//   this.setState({
//     xlm_price: price,
//   });
// }




  getUrl() {
    const pathArray = this.getPathArray();

    if (pathArray.length < 5 || pathArray[3] === "") {
      this.loadCounterAsset()
      // this.getAssetsDB()
    }
    else {
      this.setStateFromUrl()
    }
  }


  getPathArray() {
    return window.location.pathname.split('/');
  }


  loadCounterAsset = () => {

    // const counter_asset = {
    //   asset_code: 'SCOP',
    //   asset_issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'
    // }

    const counter_asset = {
      asset_code: 'USDC',
      asset_issuer: 'GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN'
    }

    window.history.replaceState(null,null, `/trade/${counter_asset.asset_code}-XLM/${counter_asset.asset_issuer}/native`);
    this.setStateFromUrl()
  }


  setStateFromUrl() {

    const pathArray = this.getPathArray();
    const splitPair = pathArray[2].split('-');

    const pair = {
      counter_code: splitPair[0],
      base_code: splitPair[1],
    }

    const issuers = {
      counter_issuer: pathArray[3],
      base_issuer: pathArray[4],
    }

    const counter_asset = {
      asset_code: pair.counter_code,
      asset_issuer: issuers.counter_issuer,
    }
    const base_asset = {
      asset_code: pair.base_code,
      asset_issuer: issuers.base_issuer,
    }

    this.setStatePair(counter_asset, base_asset)
    this.getCounterAssetDB(counter_asset)
    this.getBaseAssetDB(base_asset);
    this.checkAccount(issuers.counter_issuer)
    this.getAssetsDB(0, 'start')
    this.checkWatchlist(counter_asset)
  }


  setStatePair(counter_asset, base_asset) {

    this.setState({
      counter_asset: {
        asset_code: counter_asset.asset_code,
        asset_issuer: counter_asset.asset_issuer,
      },
      base_asset: {
        asset_code: base_asset.asset_code,
        asset_issuer: base_asset.asset_issuer,
      }
    })

    const title = `${counter_asset.asset_code}-${base_asset.asset_code} trade on Stellar DEX`
    // const description = ``;
    getTitle(title);
    window.document.querySelector('meta[property="og:title"]').setAttribute("content", title);

    setTimeout(() => {

      this.setState({
        orderbook: false,
        trades_aggregate: false,
        trades: []
      },
      () => {
        this.getOrderbook()
        this.getTrades()
        this.tradeAggregation()
        this.getAsset()
        // this.getPayments()
        // this.getHolders()
        // this.getTrustlines()
        // this.getAssetOffers()
        // this.getMarketsForAsset()
      })
    }, 100)
  }


  getCounterAssetDB = (counter_asset) => {

    axios.get(`${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${counter_asset.asset_code}&asset_issuer=${counter_asset.asset_issuer}`)
      .then((response) => {

        if (response.data.length) {

          const data = response.data[0];

          if (data.home_domain) {
            this.checkBlockDomain(data.home_domain)
          }

          this.setState({
            counter_asset_db: data,
            counter_name: data.name,
            counter_image: data.image,
            counter_domain: data.home_domain,

            counter_base_price: data.price,
            counter_usd_price: (data.price * this.state.price),
            counter_change1d_color: data.change_24h_color,
            counter_change1d: data.change24,
            base_volume: data.base_volume,
            home_domain: data.home_domain,
            asset: {
              num_accounts: data.holders,
              amount: data.supply,
            }
          })

          window.document.querySelector('meta[property="og:image"]').setAttribute("content", data.image);
          window.document.querySelector('meta[property="og:image:width"]').setAttribute("content", '512');
          window.document.querySelector('meta[property="og:image:height"]').setAttribute("content", '512');

          window.document.querySelector('meta[property="twitter:image"]').setAttribute("content", data.image);
          window.document.querySelector('meta[property="twitter:image:src"]').setAttribute("content", data.image);
        }
      })
      .catch((error) => {
        console.log(error);
    });
  }


  checkBlockDomain = (domain) => {

    fetch(`https://api.stellar.expert/explorer/directory/blocked-domains/${domain}`)
      .then((response) => {
        return response.json()
      })
      .then((data) => {

        this.setState({
          blocked_domain: data.blocked
        })
      })
      .catch(error => {
        console.log('checkBlockDomain error: ', error)
      })
  }


  getBaseAssetDB(base_asset) {

    if (base_asset.asset_code === 'XLM' && base_asset.asset_issuer === 'native') {
      this.setState({
          base_name: 'Stellar',
          base_image: '/img/logo/stellar-logo-gray.png',
          base_domain: 'stellar.org',
        })
      return;
    }

    axios.get(`${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${base_asset.asset_code}&asset_issuer=${base_asset.asset_issuer}`)
      .then((response) => {

        const data = response.data[0];

        if (response.data.length) {
          this.setState({
            base_asset_db: data,
            base_name: data.name,
            base_image: data.image,
            base_domain: data.home_domain,
          })
        }
      })
      .catch((error) => {
        console.log(error);
    });
  }


  getAssetsDB = (page_count, type) => {

    let limit = 30;
    if (!page_count) {
      var page_count = 0;
    }

    getMarketAssets(page_count, limit)
      .then(result => {
        const counter_asset = result.data[0];

        if (!this.state.modal && type !== 'start') {
          // this.setStatePair(counter_asset, this.state.base_asset)
          // this.getUrl(counter_asset)
          window.history.replaceState(null,null, `/trade/${counter_asset.asset_code}-XLM/${counter_asset.asset_issuer}/native`);
          this.setStateFromUrl()
        }

        if (result.data && result.data.length) {
          this.setState({
            assets_db: this.state.assets_db.concat(result.data)
          })
        }
      })
      .catch(error => {
        console.log('error: ', error)
      })
  }



  getAsset = (type) => {

    Server.assets()
      .forIssuer(this.state.counter_asset.asset_issuer)
      .forCode(this.state.counter_asset.asset_code)
      .call()
      .then((resp) => {
        const asset = resp.records[0];

        this.setState({
          asset: asset,
        })

        if (type !== 'second') {
          this.getToml(asset);
          this.getAsset('second');
          return
        }

        // setInterval(() => {
          this.getAsset('second');
        // }, 100000)
      })
      .catch((err) => {
        console.error('err: ', err);
    });
  }



  getToml(asset) {

    const home_domain = asset._links.toml.href.replace('https://', '').replace('/.well-known/stellar.toml', '');
    // const part = this.props.match.params.id.split('-');

    if (home_domain) {

      this.setState({ home_domain })

      StellarSdk.StellarTomlResolver.resolve(home_domain)
        .then(assetToml => {

          this.setState({
            toml_full: assetToml,
          })

          assetToml.CURRENCIES.forEach((item) => {

            if (item.code === this.state.counter_asset.asset_code && item.issuer === this.state.counter_asset.asset_issuer) {

              this.setState({
                toml: item,
                load_toml: true,
                // counter_image: item.image,
                // counter_name: item.name,
                // counter_domain: home_domain,
              },
                () => {
                  // if (this.state.trades_aggregate) {
                  //   if (this.state.base_asset.asset_code === 'XLM' && this.state.base_asset.asset_issuer === 'native') {
                  //     this.sendAssetData()
                  //   }
                  // }
                })
            }
          });
        })
        .catch(error => {
          console.log('toml err', error);
          // this.getTomlImage('error')
      });
    }
  }


  getBase() {

    if (this.state.base_asset.asset_issuer == 'native') {
      var base = new StellarSdk.Asset.native();
    } else {
      var base = new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)
    }

    return base;
  }

  streamOrderbook(asset_code, asset_issuer) {

    const stream_orderbook = Server.orderbook(this.getBase(), new StellarSdk.Asset(asset_code, asset_issuer))
      .limit(this.state.orderbook_limit)
      .cursor('now')
      .stream({
        onmessage: (message) => {
          const path = window.location.pathname.split('/')[1];

          if (path !== 'trade') {
            stream_orderbook()
            return;
          }

          this.setOrderbook(message)
          this.setSpreadState(message)
        }
      });

    this.setState({ stream_orderbook })
  }


  getOrderbook() {

    const asset_code = this.state.counter_asset.asset_code;
    const asset_issuer = this.state.counter_asset.asset_issuer;

    Server.orderbook(this.getBase(), new StellarSdk.Asset(asset_code, asset_issuer))
      .limit(this.state.orderbook_limit)
      .call()
      .then(result => {

        if (result) {
          this.streamOrderbook(asset_code, asset_issuer)
          this.setOrderbook(result)

          const buy_price_orderbook = Number(parseNumber(result.asks.length
            ? Number((result.asks[0].price_r.d / result.asks[0].price_r.n).toFixed(7))
            : 0.00)).toFixed(7);
          const sell_price_orderbook =Number(parseNumber(result.bids.length
            ? Number((result.bids[0].price_r.d / result.bids[0].price_r.n).toFixed(7))
            : 0.00)).toFixed(7);

          this.props.change('buy_price', sell_price_orderbook);
          this.props.change('sell_price', buy_price_orderbook);

          this.setState({
            buy_price_orderbook: sell_price_orderbook,
            sell_price_orderbook: buy_price_orderbook,
          })

          this.setSpreadState(result)
        }
      })
      .catch((err) => {
        // console.log(err);
    })
  }


  setSpreadState = (result) => {

    const orig_buy_price_orderbook = (result.asks.length
      ? Number((result.asks[0].price_r.d / result.asks[0].price_r.n).toFixed(7))
      : 0.00) ;
    const orig_sell_price_orderbook = (result.bids.length
      ? Number((result.bids[0].price_r.d / result.bids[0].price_r.n).toFixed(7))
      : 0.00);
    let spread = (orig_sell_price_orderbook - orig_buy_price_orderbook)
    spread = numFormat(100 / (orig_sell_price_orderbook / spread), 2)

    this.setState({
      orig_sell_price_orderbook,
      orig_buy_price_orderbook,
      spread,
    })
  }


  setOrderbook = (orderbook) => {

    let maxDepthBuy = 0,
        maxDepthSell = 0,
        buy_counter_amount = 0,
        sell_counter_amount = 0,
        buy_base_amount = 0,
        sell_base_amount = 0;

    if (orderbook.asks.length) {
      orderbook.asks.forEach((item, index) => {
        maxDepthBuy = (maxDepthBuy + Number(item.amount));
        buy_counter_amount = (buy_counter_amount + (item.amount * item.price))
        buy_base_amount = (buy_base_amount + Number(item.amount))
      });
    }


    if (orderbook.bids.length) {
      orderbook.bids.forEach((item, index) => {

        maxDepthSell = (maxDepthSell + Number(item.amount / item.price));
        sell_counter_amount = (sell_counter_amount + Number(item.amount))
        sell_base_amount = (sell_base_amount + (item.price > 0 ? (item.amount / item.price) : 0))
      });
    }

    let orig_buy_price_orderbook = (orderbook.asks.length
      ? Number((orderbook.asks[0].price_r.d / orderbook.asks[0].price_r.n).toFixed(7))
      : 0.00);
    let orig_sell_price_orderbook = (orderbook.bids.length
      ? Number((orderbook.bids[0].price_r.d / orderbook.bids[0].price_r.n).toFixed(7))
      : 0.00);

    this.setState({
      orderbook,
      maxDepthBuy,
      maxDepthSell,
      buy_counter_amount,
      sell_counter_amount,
      buy_base_amount,
      sell_base_amount,
      orig_buy_price_orderbook,
      orig_sell_price_orderbook
    })
  }


  renderBuyOffers = ({ theme }) => {

    let depth = 0;
    const items = this.state.orderbook.asks.map((item, index) => {
      depth += Number(item.amount);

      let depthPercentage = Math.min(100, Number(depth / this.state.maxDepthBuy * 100).toFixed(1)) * 2;

      let them_color = '#fafbfefc';
      let striped_color = 'rgb(255, 255, 255)';

      if (theme.className === 'theme-dark') {
        them_color = '#232329';
        striped_color = '#38373f';
      }

      const green_color = '168, 251, 186';
      const price = (item.price_r.d / item.price_r.n).toFixed(7);

      return (
        <CopyToClipboard text={price} onCopy={() => {}} key={index}>
          <tr onClick={() => ToastStore.success(`Price: ${numFormat(price, 7)} сopied`)}
              style={{
                              borderBottom: '1px solid rgba(158, 158, 158, 0.1)',
                              background: 'linear-gradient(to left, rgba('+green_color+') '+depthPercentage+'%, '+(index % 2 === 0 ? striped_color : them_color)+' '+depthPercentage+'%)'}}>
              <td><small>{numFormat(depth, 2)}</small></td>
              <td>{numFormat(item.amount, 2)}</td>
              <td>{numFormat((item.amount * item.price), 7)}</td>
              <td>{numFormat(price, 7)}</td>
          </tr>
        </CopyToClipboard>
      )
    })

    return items
  }


  renderSellOffers({ theme }) {

    let depth = 0;
    const items = this.state.orderbook.bids.map((item, index) => {

      depth += Number((item.amount / item.price));

      let depthPercentage = Math.min(100, Number(depth / this.state.maxDepthSell * 100).toFixed(1)) * 2;

      let them_color = '#fafbfefc';
      let striped_color = 'rgb(255, 255, 255)';

      if (theme.className === 'theme-dark') {
        them_color = '#232329';
        striped_color = '#38373f';
      }

      const red_color = '255, 191, 191';
      const price = (item.price_r.d / item.price_r.n).toFixed(7);

      return (
        <CopyToClipboard text={price} onCopy={() => {}} key={index}>
          <tr onClick={() => ToastStore.success(`Price: ${numFormat(price, 7)} сopied`)}
              style={{borderBottom: '1px solid rgba(158, 158, 158, 0.1)',
                background: 'linear-gradient(to right, rgba('+red_color+') '+depthPercentage+'%, '+(index % 2 === 0 ? striped_color : them_color)+' '+depthPercentage+'%)'}}>
            <td>{numFormat(price, 7)}</td>
            <td>{numFormat(item.amount, 7)}</td>
            <td>{numFormat((item.amount / item.price), 2)}</td>
            <td><small>{numFormat(depth, 2)}</small></td>
          </tr>
        </CopyToClipboard>
      )
    })

    return items
  }



  streamTrades = (asset_code, asset_issuer) => {

    const stream_trades = Server.trades()
      .forAssetPair(this.getBase(), new StellarSdk.Asset(asset_code, asset_issuer))
      .cursor('now')
      .stream({
        onmessage: (message) => {

          this.state.trades.pop()

          this.setTradesState([message, ...this.state.trades])

          // this.setState({
          //   trades: [message, ...this.state.trades],
          // });

          // if (message.counter_asset_code === asset_code && message.counter_asset_issuer === asset_issuer) {
          //   modState();
          //   console.log('modState1')
          // }
          // else if (message.base_asset_code === asset_code && message.base_asset_issuer === asset_issuer) {
          //   modState();
          //   console.log('modState2')
          // }
        }
    });

    this.setState({
      stream_trades
    })
  }


  getTrades() {

    const asset_code = this.state.counter_asset.asset_code;
    const asset_issuer = this.state.counter_asset.asset_issuer;

    this.setState({ load_more: false })

    const ServerTrades = Server.trades()
      .forAssetPair(this.getBase(), new StellarSdk.Asset(asset_code, asset_issuer))
      .order('desc')
      .limit(limit_items);

      if (this.state.cursor_trades) {
        ServerTrades.cursor(this.state.cursor_trades);
      }

    ServerTrades
      .call()
      .then((result) => {
        if (result && result.records.length) {
          // this.setTradesState(result.records)
          const trades = this.state.trades.concat(result.records);

          this.setTradesState(trades)

          if (result.records.length < limit_items || result.records.length === 0) {
            this.setState({ trades_more_btn: false });
          } else {
            this.setState({ trades_more_btn: true });
          }

          this.setState({
            cursor_trades: result.records[result.records.length - 1].paging_token,
            load_more: true,
          });

          this.streamTrades(asset_code, asset_issuer)
        }
      })
      .catch((err) => {
        ToastStore.error('Error receiving trades');
        console.log('err trades', err);
        this.setState({ isTrades: false })

        swal({
          title: 'No trading history',
          text: 'It looks like this asset has no trading history for the chart. Make a few trading transactions and the charts will be filled.',
          icon: 'info'
        })
    })

    if (this.state.base_asset.asset_code !== 'XLM' && this.state.base_asset.asset_code !== 'native') {
      this.getCounterPriceBaseForCap()
      this.getBasePriceBaseForCap()
    }
  }


  getCounterPriceBaseForCap = () => {

    const asset_code = this.state.counter_asset.asset_code;
    const asset_issuer = this.state.counter_asset.asset_issuer;

    Server.trades()
      .forAssetPair(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset_code, asset_issuer))
      .order('desc')
      .limit(1)
      .call()
      .then((result) => {

        if (result) {
          this.setState({
            counter_xlm_price_for_cap : result.records[0]
          })
        }
      })
      .catch((error) => {
        console.log('error counter_base_price_for_cap', error);
        console.log('error response', error.response);
      })
  }


  getBasePriceBaseForCap = () => {

    const asset_code = this.state.base_asset.asset_code;
    const asset_issuer = this.state.base_asset.asset_issuer;

    Server.trades()
      .forAssetPair(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset_code, asset_issuer))
      .order('desc')
      .limit(1)
      .call()
      .then((result) => {
        if (result) {
          const base_xlm_price_for_cap = (result.records[0].price.d / result.records[0].price.n);
          const counter_usd_price = ((this.state.counter_base_price * base_xlm_price_for_cap) * this.state.price);

          this.setState({
            counter_usd_price,
            base_xlm_price_for_cap
          })
        }
      })
      .catch((error) => {
        console.log('error base_price_for_cap', error);
        console.log('error response', error.response);
      })
  }

  setTradesState(trades) {

    if (trades.length) {
      let item = trades[0];
      let counter_base_price = (item.price.d / item.price.n);
      let counter_usd_price = (counter_base_price * this.state.price);
      let type_trade_class = (item.base_is_seller ? 'text-danger' : 'text-success');

      this.setState({
        trades,
        counter_base_price,
        counter_usd_price,
        type_trade_class,
      })

      // console.log('tradeAggregation() from Trades')
      this.tradeAggregation()
    }
  }


  refreshTrades() {
    this.state.stream_trades()
    this.setState({
      trades: [],
      cursor_trades: false,
    }, () => this.getTrades())
  }


  refreshOrderbook() {
    this.state.stream_orderbook()
    this.setState({
      orderbook: false
    }, () => this.getOrderbook())
  }

  refreshTradeAggregate() {
    this.setState({
      refresh_trades_aggregate: true,
    }, () => this.tradeAggregation())
  }



  streamMyTrades(address) {

    const stream_trades = Server.trades()
      .forAccount(address)
      .cursor('now')
      .stream({
        onmessage: (message) => {
          // console.log('message my_trades: ', message)

          // this.setTradesState([message, ...this.state.trades])
        }
    });

    // this.setState({
    //   stream_trades
    // })
  }



  getTradesForAccount(address) {

    this.setState({ load_more: false })

    const ServerMyTrades = Server.trades()
      .forAccount(address)
      .limit(limit_items)
      .order('desc');

      if (this.state.cursor_my_trades) {
        ServerMyTrades.cursor(this.state.cursor_my_trades);
      }

    ServerMyTrades
      .call()
      .then( (result) => {

        if (result) {
          // this.setTradesState(result.records)
          const trades = this.state.my_trades.concat(result.records);

          this.setMyTradesState(trades)


          if (result.records.length < limit_items || result.records.length === 0) {
            this.setState({ my_trades_more_btn: false });
          } else {
            this.setState({ my_trades_more_btn: true });
          }

          this.setState({
            cursor_tmy_trades: result.records[result.records.length - 1].paging_token,
            load_more: true,
          });

          this.streamMyTrades(address)
        }
      })
      .catch( (err) => {
        console.error(err);
      })
  }



  setMyTradesState(my_trades) {
    this.setState({
      my_trades
    })
  }



  getTrustlines = () => {

    const { counter_asset, cursor_trustlines, trustlines  } = this.state;

    this.setState({ load_more: false })

    getTrustlines(counter_asset, limit_items, cursor_trustlines).then(result => {

      if (result.data) {
        const records = result.data._embedded.records;

        if (records.length < limit_items || records.length === 0) {
          this.setState({ trustlines_more_btn: false });
        } else {
          this.setState({ trustlines_more_btn: true });
        }

        this.setState({
          trustlines: trustlines.concat(records),
          cursor_trustlines: records[records.length - 1].paging_token,
          load_more: true,
        })
      }
    })
  }


  getAssetOffers = () => {

    const { counter_asset, asset_offers, cursor_asset_offers  } = this.state;

    this.setState({ load_more: false })

    getAssetOffers(counter_asset, limit_items, cursor_asset_offers).then(result => {

      if (result.data) {
        const records = result.data._embedded.records;

        if (records.length < limit_items || records.length === 0) {
          this.setState({ asset_offers_more_btn: false });
        } else {
          this.setState({ asset_offers_more_btn: true });
        }

        this.setState({
          asset_offers: asset_offers.concat(records),
          cursor_asset_offers: records.length ? records[records.length - 1].paging_token : 0,
          load_more: true,
        })
      }
    })
  }


  getMarketsForAsset = () => {

    const { counter_asset, markets, cursor_markets  } = this.state;

    this.setState({ load_more: false })

    getMarkets(counter_asset, limit_items, cursor_markets).then(result => {

      const markets_parse = JSON.parse(result.data.markets)

      if (markets_parse) {
        const records = markets_parse._embedded.records;

        if (records.length) {

          if (records.length < limit_items || records.length === 0) {
            this.setState({ markets_more_btn: false });
          } else {
            this.setState({ markets_more_btn: true });
          }

          this.setState({
            markets: markets.concat(records),
            cursor_markets: records[records.length - 1].paging_token,
            load_more: true,
            assets: result.data.assets,
          })
        }
      }
    })
  }



  getHolders = async () => {

    const { counter_asset, cursor_holders, holders  } = this.state;

    const formData = new FormData();
    formData.append('asset_code', counter_asset.asset_code);
    formData.append('asset_issuer', counter_asset.asset_issuer);
    formData.append('cursor', cursor_holders ? cursor_holders : '');
    formData.append('limit', limit_items);

    this.setState({ load_more: false })

    axios.post(`${GLOBE.API_URL}/getHolders`, formData)
      .then((response) => {

        if (response.data) {
          const records = response.data._embedded.records;

          if (records.length < limit_items || records.length === 0) {
            this.setState({ holders_more_btn: false });
          } else {
            this.setState({ holders_more_btn: true });
          }

          this.setState({
            holders: holders.concat(records),
            cursor_holders: records[records.length - 1].paging_token,
            load_more: true,
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }



  getHoldersX() {


    let holders = [];

    const sortMap = (holders, cursor) => {

      holders.sort((a, b) => {
        return b.balance - a.balance
      })

      this.setState({
        holders: this.state.holders.concat(holders),
        cursor_holders: cursor,
        load_more: true,
      })
    }

    this.setState({ load_more: false })

    const ServerHolders = Server.accounts()
      .forAsset(new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer))
      .limit(limit_items)
      .order('desc');

      if (this.state.cursor_holders) {
        ServerHolders.cursor(this.state.cursor_holders);
      }

    ServerHolders
      .call()
      .then((result) => {

        if (result.records.length) {

          if (result.records.length < limit_items || result.records.length === 0) {
            this.setState({ holders_more_btn: false });
          } else {
            this.setState({ holders_more_btn: true });
          }

          result.records.map((item, index) => {
            const balance = item.balances.filter((item) => {
              return item.asset_code === this.state.counter_asset.asset_code && item.asset_issuer === this.state.counter_asset.asset_issuer;
            });

            holders.push({
              address: item.account_id,
              balance: balance[0].balance,
              date: item.last_modified_time,
              asset: {
                asset_code: balance[0].asset_code,
                asset_issuer: balance[0].asset_issuer,
              }
            })
          })

          const cursor = result.records[result.records.length - 1].paging_token;

          // this.setState({
          //   holders: this.state.holders.concat(holders),
          //   cursor_holders: cursor,
          //   load_more: true,
          // })

          sortMap(holders, cursor)
        }
      })
      .catch( (err) => {
        console.error(err);
    })
  }




  // getPayments() {
  //
  //   const asset_code = this.state.counter_asset.asset_code;
  //   const asset_issuer = this.state.counter_asset.asset_issuer;
  //
  //   this.setState({ load_more: false })
  //
  //   if (this.state.cursor_payments) {
  //     var cursor = '&cursor='+this.state.cursor_payments;
  //   } else {
  //     var cursor = '';
  //   }
  //
  //   const url = `https://api.stellar.expert/api/explorer/public/payments?asset=${asset_code}-${asset_issuer}&order=desc&limit=${limit_items}${cursor}`;
  //
  //   fetch(url)
  //     .then((response) => {
  //       return response.json();
  //      })
  //     .then((result) =>  {
  //       const records = result._embedded.records;
  //
  //       if(records.length < limit_items || records.length === 0) {
  //         this.setState({ payments_more_btn: false });
  //       } else {
  //         this.setState({ payments_more_btn: true });
  //       }
  //
  //       this.setState({
  //         payments: this.state.payments.concat(records),
  //         cursor_payments: records[records.length - 1].paging_token,
  //         load_more: true,
  //       })
  //     })
  //     .catch( (err) => {
  //       console.log('getPayments err: ', err)
  //     });
  // }




  tradeAggregation = (dayResolve) => {

    const asset_code = this.state.counter_asset.asset_code;
    const asset_issuer = this.state.counter_asset.asset_issuer;
    const base = this.getBase();
    const counter = new StellarSdk.Asset(asset_code, asset_issuer);
    const startTime = 0;
    const endTime = 0;
    const resolution = dayResolve ? dayResolve : 86400000;
    const offset = 0;

    Server.tradeAggregation(base, counter, startTime, endTime, resolution, offset)
      .order('desc')
      .limit(this.trade_aggregation_limit)
      .call()
      .then((result) => {

        const resultLength = result.records.length

        if (!resultLength) {
          this.tradeAggregation(3600000)
          return
        }

        if (resultLength === 1) {
          this.setState({ isTradesAggregate: false })
        }

        if (resultLength) {

          let first_item = result.records[0];
          const price_close = parseNumber(first_item.close_r.d / first_item.close_r.n);
          const price_open = parseNumber(first_item.open_r.d / first_item.open_r.n);
          const price_low = parseNumber(first_item.high_r.d / first_item.high_r.n);
          const price_high = parseNumber(first_item.low_r.d / first_item.low_r.n);
          let counter_change1d = this.change24(result).change24;

          let progress_price = (100 / (price_high / price_low))

          let color_price = '', counter = 0
          const low_close = (price_close - price_low)
          const high_close = (price_high - price_close)

          if (high_close < low_close) {
            counter = (low_close / high_close)

            if (counter > 1) progress_price = 55
            if (counter > 2) progress_price = 60
            if (counter > 4) progress_price = 65
            if (counter > 10) progress_price = 70
            if (counter > 20) progress_price = 75
            if (counter > 60) progress_price = 80
            if (counter > 80) progress_price = 85
            if (counter > 90) progress_price = 90
            if (counter > 100) progress_price = 95

            if (price_close === price_high) progress_price = 99
          }

          else if (high_close > low_close) {
            counter = (high_close / low_close)

            if (counter > 1) progress_price = 45
            if (counter > 2) progress_price = 40
            if (counter > 4) progress_price = 35
            if (counter > 10) progress_price = 30
            if (counter > 20) progress_price = 25
            if (counter > 60) progress_price = 20
            if (counter > 80) progress_price = 15
            if (counter > 90) progress_price = 10
            if (counter > 100) progress_price = 5

            if (price_close === price_low) progress_price = 1
          }

          // colors
          if (progress_price >= 45 && progress_price <= 55) {
            color_price = 'blue'
          }
          if (progress_price > 60) {
            color_price = '' // success
          }
          if (progress_price < 45) {
            color_price = 'yellow'
          }
          if (progress_price <= 20) {
            color_price = 'pink'
          }


          let change7d = '', change_7d_color = '', chart7d = '';
          if (resultLength >= 7) {
            change7d = this.change7d(result).change7d;
            change_7d_color = this.change7d(result).change_7d_color;
            chart7d = this.change7d(result).chart7d;
          }


          if (counter_change1d.length > 5) {
            counter_change1d = counter_change1d.substring(0, counter_change1d.length - 3);
          }

          this.setState({
            progress_price,
            color_price,
            trades_aggregate: result.records,
            refresh_trades_aggregate: false,
            counter_price_0d: (result.records[0].close_r.d / result.records[0].close_r.n),
            base_volume: result.records[0].base_volume,
            price_low,
            price_high,
            price_open,
            price_close,
            // usd_volume: (this.state.base_volume * this.state.xlm_price.crypto_price.xlm_usd),
            counter_volume: first_item.counter_volume,
            // counter_price_1d: (result.records[1].close_r.D / result.records[1].close_r.N),
            // counter_price_7d: (result.records[6].close_r.D / result.records[6].close_r.N),
            counter_change1d,
            counter_change1d_color: (resultLength > 0 ? this.change24(result).change_24h_color : 'secondary'),
            counter_change7d: (resultLength > 6 ? this.change7d(result).change7d : 0),
            counter_change7d_color: (resultLength > 6 ? this.change7d(result).change_7d_color : 'secondary'),
            change24: (resultLength >= 1 ? this.change24(result).change24 : 0),
            change_24h_color: (resultLength > 0 ? this.change24(result).change_24h_color : 'secondary'),
            change7d: change7d,
            change_7d_color: change_7d_color,
            chart7d: chart7d,
          },

            () => {

              const description = `Asset: ${asset_code} (${this.state.counter_asset.name}) | Price: ${price_close} ${this.state.base_asset.asset_code}  | Change 24h: ${this.state.change24}% | Volume 24h: ${this.state.change24} ${this.state.base_asset.asset_code} | Supply: ${this.state.counter_asset.supply} ${asset_code} | Holders: ${this.state.counter_asset.holders} | Domain: ${this.state.counter_asset.home_domain}`;
              getTitle(false, description);

              const localAsset = localStorage.getItem('up_token');

              if (JSON.stringify(this.state.up_token) !== localAsset) {

                if (this.state.base_asset.asset_code === 'XLM' && this.state.base_asset.asset_issuer === 'native') {
                  this.sendAssetData()
                }

                // if (!this.state.update_asset_data) {
                //   this.setState({
                //     update_asset_data: true
                //   })
                //   this.sendAssetData()
                // }
              }
            });
          // this.streamTradeAgregation(asset_code, asset_issuer)
        }
        else {
          swal({
            title: 'No trading history',
            text: 'This Asset has no trading history yet. Just a few transactions and data will appear.',
            icon: 'info'
          })
        }
      })
      .catch((error) => {
        console.log('err trades', error);
        console.log('err trades.response', error.response);

        // this.trade_aggregation_limit = 100;
        // this.tradeAggregation(3600000)
        //
        // if (this.tryAggregateCnt > 2) {
          this.setState({ isTradesAggregate: false })
        // }
        //
        // this.tryAggregateCnt++

      })
  }


  getChange(records, days) {

    const today = Number((1 / records[0].avg).toFixed(7));
    const yestoday = Number((1 / records[records.length === 1 ? 0 : days].close).toFixed(7));

    let change_color, change;

    if (today > yestoday) {
      more();
    }
    else if (today === yestoday) {
      change_color = 'secondary';
      change = '0';
    }
    else {
      less();
    }


    function more() {
      change = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
      change_color = 'success';
    }

    function less() {
      change = Number(100 - yestoday * 100 / today).toFixed(2);
      change_color = 'danger';
    }

    return {
      change: change,
      change_color: change_color,
    };
  }


  change24(val) {

    const today = (1 / val.records[0].close).toFixed(7)
    const index = val.records.length === 1 ? 0 : 1
    var yestoday = (1 / val.records[index].close).toFixed(7);



    if (today > yestoday) {
      more();
    }
    else if (today === yestoday) {
      val.change_24h_color = 'secondary';
      val.change24 = '0.00';
    }
    else {
      less();
    }

    function more() {
      // val.sign = '+';
      val.change24 = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
      val.change_24h_color = 'success';
    }

    function less() {
      val.change24 = Number(100 - yestoday * 100 / today).toFixed(2);
      val.change_24h_color = 'danger';
    }

    return val;
  }


  change7d = (val) => {

    val.records = val.records ? val.records : val

    const today = (1 / val.records[0].close).toFixed(7)
    const yestoday = (1 / val.records[6].close).toFixed(7);

    if (today > yestoday) {
      more();
    }
    else if (today === yestoday) {
      val.change_7d_color = 'secondary';
      val.change7d = '0.00';
    }
    else {
      less();
    }

    function more() {
      // val.sign = '+';
      val.change7d = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
      val.change_7d_color = 'success';
    }

    function less() {
      val.change7d = Number(100 - yestoday * 100 / today).toFixed(2);
      val.change_7d_color = 'danger';
    }

    // 7d
    val.chart7d = val.records.filter((item, index) => index < 7)
    // val.records.forEach((item, index) => {
    //   if (index+1 <= 7) {
    //     var price = Number((item.close_r.d / item.close_r.n).toFixed(7));
    //     if (val.records[0].close > item.close) {
    //       price = (price * 1.5).toFixed(7)
    //     }
    //     else if (val.records[0].close < item.close) {
    //       price = (price / 1.5).toFixed(7)
    //     }
    //     val.chart7d.unshift({ name: index, price: price });
    //   }
    // });

    return val;
  }


  // streamTradeAgregation() {
  //
  //   const asset_code = 'SCOP';
  //   const asset_issuer = 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ';
  //
  //   var base = new StellarSdk.Asset.native();
  //   var counter = new StellarSdk.Asset(asset_code, asset_issuer);
  //   var startTime = 0;
  //   var endTime = 0;
  //   var resolution = 86400000;
  //   var offset = 0;
  //
  //   Server.tradeAggregation(base, counter, startTime, endTime, resolution, offset)
  //     .limit(7)
  //     .call()
  //     .then((result) => {
  //       console.log('message tradeAggregation: ', result);
  //     })
  //     .catch((err) => {
  //       console.log('tradeAggregation', err);
  //     })
  // }


  onRefresh = () => {
    // your async logic here
    this.setState(prevState => ({ refresh: !prevState.refresh }));
    setTimeout(() => this.setState({ refresh: false }), 30000);
  };


  refreshSummary = () => {
    this.setState({
      // holders: [],
      refresh_trades_aggregate: true,
    }, () => {
      // this.getHolders()
      this.tradeAggregation()
    })
  }


  changeAddress(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.setState({
      select_address: address,
      account: false,
      offers: false,
      trust_base: false,
      trust_counter: false,
      my_trades: [],
    })

    this.getAccount(address, true);
    this.getSigner(address);
    this.getTradesForAccount(address)
    // this.streamAccount(address);
    this.getOffers(address);

    localStorage.setItem('select_address', address)
  }


  refreshAccount() {
    if (this.state.select_address) {
        this.getAccount(this.state.select_address, true)
        this.getAccountsBalances();
    }
  }


  getAccount(account_id, type) {

    this.getOffers(account_id)

    if (type) {
      this.setState({ loading_account: true });
      ToastStore.info('Check Account...');
    }

    Server.loadAccount(account_id)
      .then((account) => {
        this.setState({ loading_account: false, account });
        this.checkTrustline(account, type)

        let reserve_balance = ((account.subentry_count * baseReserve()) + minBalance());

        let native_balance = 0, counter_balance = 0, base_balance = 0, available_balance = 0;

        // account.balances.reverse()
        account.balances.reverse().forEach((item, index) => {
          if (item.asset_type === 'native') {
            native_balance = item.balance
            available_balance = (item.balance - reserve_balance)
          }
          if (item.asset_code === this.state.counter_asset.asset_code
            && item.asset_issuer === this.state.counter_asset.asset_issuer) {
            counter_balance = item.balance
          }
          if (item.asset_code === this.state.base_asset.asset_code
            && item.asset_issuer === this.state.base_asset.asset_issuer) {
            base_balance = item.balance
          }
        })

        this.setState({
          account,
          reserve_balance,
          counter_balance,
          base_balance,
          native_balance,
          available_balance,
        });
      })
      .catch( (err) => {
        console.log('error: ', err.response);
        this.setState({ loading_account: false });
        ToastStore.error('Load Account Error...');
    })
  }


  checkTrustline = (account, type) => {
    let trust_base = false;

    const trust_counter = account.balances.some(item => {
      return (item.asset_code === this.state.counter_asset.asset_code && item.asset_issuer === this.state.counter_asset.asset_issuer)
    })

    if (this.state.base_asset.asset_code !== 'XLM' && this.state.base_asset.asset_issuer !== 'native') {
      trust_base = account.balances.some(item => {
        return (item.asset_code === this.state.base_asset.asset_code && item.asset_issuer === this.state.base_asset.asset_issuer)
      })
      if (trust_base) {
        this.setState({ trust_base })
      }
    }

    if (trust_counter) {
      this.setState({ trust_counter })
    }
    else if (type) {
      this.noTrustlineMessage()
    }
  }

  swichTrustlineAssets = (typeAsset) => {
    let asset = this.state.asset
    if (typeAsset && typeAsset === 'base') {
      asset = this.state.base_asset
    }
    return asset
  }

  setTrustline = (typeAsset) => {

    const asset = this.swichTrustlineAssets(typeAsset)

    getAuth()
        .then((value) => {
          if (value === 'accept') {
            setTrustTx()
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });


    const setTrustTx = () => {

      this.setState({ loading_account: true })

      Server.loadAccount(this.state.select_address)
          .then((sourceAccount) => {

            const submitTx = (num) => {
              return Server.submitTransaction(this.state.transaction);
            }

            if (!this.state.transaction) {

              this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
              }))
              .setTimeout(100)
              .build();


              if (checkConnectProvider(this.state.select_address)) {

                return signConnectProvider(this.state.transaction, this.state.select_address).then(result => {
                  this.state.transaction = result
                  return submitTx();
                })
              }
              else {

                this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

                const master_weight = sourceAccount.signers.reverse()[0].weight;

                if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

                  this.setState({
                    // transaction: this.state.transaction,
                    account: sourceAccount,
                    need_add_signers: true,
                    btn_name: 'Set Trustline',
                    btn_icon: 'cube',
                    type_tx: 'med',
                    type_set_tx: 'trust',
                  })

                  return false;
                }
                else {
                  return submitTx();
                }
              }
            }
            else {
              return submitTx();
            }
          })
          .then((result) => {
            if (result) {

              this.setState({
                loading_account: false,
                trust_counter: true
              })

              const message = `Trustline to <b class="text-warning">${asset.asset_code}</b> has been successfully added.`
              const element = document.createElement("span");
              element.innerHTML = message;

              swal({
                title: `Added new balance!`,
                content: element,
                icon: "success",
              })

              if (typeAsset && typeAsset === 'base') {
                this.setState({ trust_base: true })
              }
            }
          })
          .catch((error) => {
            this.setState({ loading_account: false })
            ToastStore.error('Transaction error...');
            showErrorMessage(error, 'change_trust')
          });
    }
  }


  noTrustlineMessage = (typeAsset) => {

    const asset = this.swichTrustlineAssets(typeAsset)

    const message = `Add <b class="text-warning">${asset.asset_code}</b> to your balance right now?`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: `No Trustline`,
      content: element,
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then(confirm => {
        if (confirm) {
          this.setTrustline(typeAsset)
        }
      });
  }



  clickSelectAddress() {

    if (!this.state.wallets) {
      swal({
        title: "Authorization required",
        text: `Do you want to authorize or create an account right now?`,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm)
          this.confirmAction();
      })
    }
  }

  confirmAction() {
    this.setState({
      redirect_wallet: true
    })
  }


  refreshBuySell() {
    this.props.reset()

    const orig_price_orderbook = this.getOriginalPriceOrderbook()

    this.props.change('buy_price', orig_price_orderbook.sell_price_orderbook)
    this.props.change('sell_price', orig_price_orderbook.buy_price_orderbook)
  }


  getOriginalPriceOrderbook() {
    const orderbook = this.state.orderbook;
    const buy_price_orderbook = Number((orderbook.asks[0].price_r.d / orderbook.asks[0].price_r.n).toFixed(7));
    const sell_price_orderbook = Number((orderbook.bids[0].price_r.d / orderbook.bids[0].price_r.n).toFixed(7));

    return {
      buy_price_orderbook,
      sell_price_orderbook
    }
  }



  priceChange(value, type) {
    let price = value.target.value;
    this.setTotal(price, type)
  }

  setTotal = (price, type) => {

    if (type === 'buy') {
      let total = (price * this.state.buy_amount_orderbook).toFixed(7);

      this.setState({
        buy_price_orderbook: price,
        buy_total_orderbook: total,
      });

      this.props.change('buy_total', Number(total))
    }

    if (type === 'sell') {
      let total = (price * this.state.sell_amount_orderbook).toFixed(7);

      this.setState({
        sell_price_orderbook: price,
        sell_total_orderbook: total,
      });

      this.props.change('sell_total', Number(total))
    }
  }


  amountChange(value, type) {

    let amount = value.target.value

    if (type == 'buy') {

      let total = (amount * this.state.buy_price_orderbook).toFixed(7);

      this.setState({
        buy_total_orderbook: total,
        buy_amount_orderbook: amount
      });

      this.props.change('buy_total', Number(total))
    }

    if (type == 'sell') {

      let total = (amount * this.state.sell_price_orderbook).toFixed(7);

      this.setState({
        sell_total_orderbook: total,
        sell_amount_orderbook: amount
      });

      this.props.change('sell_total', Number(total))
    }
  }


  totalChange(value, type) {
    let total = value.target.value;

    if (type == 'buy') {
      let amount = (total / this.state.buy_price_orderbook).toFixed(7)

      this.setState({
        buy_total_orderbook: total,
        buy_amount_orderbook: amount,
      });

      this.props.change('buy_amount', Number(amount))
    }

    if (type == 'sell') {
      let amount = (total / this.state.sell_price_orderbook).toFixed(7)

      this.setState({
        sell_total_orderbook: total,
        sell_amount_orderbook: amount,
      });

      this.props.change('sell_amount', Number(amount))
    }
  }



  buySubmitForm = (value) => {

    let form_values = {
      price: value.buy_price,
      amount: value.buy_amount,
      total: value.buy_total,
      type: 'buy'
    }

    this.checkFormValues(form_values);
  }


  sellSubmitForm = (value) => {

    let form_values = {
      price: value.sell_price,
      amount: value.sell_amount,
      total: value.sell_total,
      type: 'sell'
    }

    this.checkFormValues(form_values);
  }


  checkFormValues(value) {

    // if (!this.state.trust_counter) {
    //   this.noTrustlineMessage()
    // }
    if (!this.state.select_address) {
      ToastStore.warning('Select Trading Account');
    }
    else if (!value.price) {
      ToastStore.warning('Enter price');
    }
    else if (!value.amount) {
      ToastStore.warning('Enter the amount of the asset');
    }
    else if (!value.total) {
      ToastStore.warning('Enter the total amount of the asset');
    }
    else {

      const message = `${value.type === 'sell' ? `<span class="text-danger">Sell</span>` : `<span class="text-success">Buy</span>`} <b><span class="text-info">${numFormat(value.amount, 7)}</span> <span class="text-warning">${this.state.counter_asset.asset_code}</span></b> right now?`;
      const element = document.createElement("span");
      element.innerHTML = message;

      // confirm
      swal({
        title: `${this.state.counter_asset.asset_code}/${this.state.base_asset.asset_code}`,
        content: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm)
          this.setOffer(value);
      })
    }
  }


  setOffer(value) {

    let asset = {};
    let base_asset;
    let counter_asset = new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer);

		if (this.state.base_asset.asset_code === 'XLM' && this.state.base_asset.asset_issuer === 'native') {
				base_asset = new StellarSdk.Asset.native();
		}
    else {
      base_asset = new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer);
    }

    asset.type = value.type

		if (value.type === 'buy') {
				asset.selling = base_asset;
				asset.buying = counter_asset;
		}
		if (value.type === 'sell') {
				asset.selling = counter_asset;
				asset.buying = base_asset;
		}

    asset.amount = String(value.amount);
    asset.price = Number(value.price);

    this.addOffer(asset);
  }




  addOffer(asset) {

    const { select_address } = this.state

    this.setState({ buy_sell_loader: true })

    Server.loadAccount(select_address)
      .then((sourceAccount) => {

        if (asset.type === 'buy') {
          var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: this.fee,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.manageBuyOffer({
              selling: asset.selling,
              buying: asset.buying,
              buyAmount: String(asset.amount),
              price: String(asset.price)
          }))
          .setTimeout(100)
          .build();
        }

        if (asset.type === 'sell') {
          var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: this.fee,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.manageSellOffer({
              selling: asset.selling,
              buying: asset.buying,
              amount: String(asset.amount),
              price: String(asset.price)
          }))
          .setTimeout(100)
          .build();
        }

        if (checkConnectProvider(select_address)) {
          return signConnectProvider(transaction, select_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {
          transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
          return Server.submitTransaction(transaction);
        }
      })
      .then((result) => {

        this.setState({
          buy_sell_loader: false,
          offerTxResult: result,
        });

        this.addNotification(result, asset.type)
        ToastStore.success('Offer successfully added!');

        const item = result.offerResults[0];
        if (item.wasImmediatelyDeleted || item.wasImmediatelyFilled || item.wasPartiallyFilled) {
          this.getTrades()
          this.tradeAggregation()
        }

        this.getAccount(select_address, false);
        this.getAccountsBalances();
        // this.sendAssetData()

        const pair = {
          buy_asset: this.state.counter_asset,
          sell_asset: this.state.base_asset,
          address: select_address,
        }

        setPairToLocal(pair);

        // save tx
        const formData = new FormData();
        formData.append('trader', select_address)
        formData.append('buy_asset', `${asset.buying.getCode()}-${asset.buying.getIssuer()}`)
        formData.append('sell_asset', `${asset.selling.getCode()}-${asset.selling.getIssuer()}`)
        formData.append('buy_amount', asset.type === 'buy' ? this.state.buy_amount_orderbook : this.state.sell_total_orderbook)
        formData.append('sell_amount', asset.type === 'sell' ? this.state.sell_amount_orderbook : this.state.buy_total_orderbook)
        formData.append('type', asset.type)

        saveTradeToDB(formData)
      })
      .catch((error) => {
        console.log('error: ', error)
        this.setState({ buy_sell_loader: false })
        showErrorMessage(error, 'manage_offer')
    });
  }



  renderTradeAddress() {
    return (
        <div>
          <div id="">
            <Field
              name="select_address"
              component={renderTextField}
              select
              label="Select Account for Trading"
              onChange={(value) => {
                this.changeAddress(value)
              }}
            >
              {
                this.state.wallets
                ? this.state.wallets.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        className={`material-form__option ${item && item.provider ?  'connect-provider' : ''}`}
                        value={item.pk}>
                        <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                      </MenuItem>
                    );
                  })
                : null
              }
            </Field>
          </div>
        </div>
    )
  }



  toggleOfferToast = () => {
    this.setState({
      showOfferToast: !this.state.showOfferToast
    })
  }


  toastDismiss = () => {
    return (
      <div>
        <Toast className="offer-notif" isOpen={this.state.showOfferToast}>
          <ToastHeader toggle={this.toggleOfferToast}>Toast title</ToastHeader>
          <ToastBody>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
          </ToastBody>
        </Toast>
      </div>
    );
  }


  loadMoreAssetsDB() {
    page_count+=1;
    this.getAssetsDB(page_count);
  }



  selectAsset(item) {

    this.toggleModal()

    if (this.state.stream_orderbook) {
      this.state.stream_orderbook()
    }
    if (this.state.stream_trades) {
      this.state.stream_trades()
    }

    if (this.state.select_asset_type == 'counter_asset') {

      this.setState({
        counter_image: item.image || '/img/digitalcoin.png',
        counter_name: item.name || 'name',
        counter_domain: item.home_domain || 'home_domain',
        counter_balance: 0,
        counter_asset: {
          asset_code: item.asset_code,
          asset_issuer: item.asset_issuer,
        }
      }, () => updateUrl())
    }
    else {
      this.setState({
        base_image: item.image || '/img/digitalcoin.png',
        base_name: item.name || 'name',
        base_domain: item.home_domain || 'home_domain',
        base_asset: {
          asset_code: item.asset_code,
          asset_issuer: item.asset_issuer,
        }
      }, () => updateUrl())
    }

    const updateUrl = () => {
      window.location = `${window.location.origin}/trade/${this.state.counter_asset.asset_code}-${this.state.base_asset.asset_code}/${this.state.counter_asset.asset_issuer}/${this.state.base_asset.asset_issuer}`

      // window.history.replaceState(null,null, `/trade/${this.state.counter_asset.asset_code}-${this.state.base_asset.asset_code}/${this.state.counter_asset.asset_issuer}/${this.state.base_asset.asset_issuer}`);
      // this.setStateFromUrl()
    }

    // return
    //
    // setTimeout(() => {
    //   // window.location = `${window.location.origin}/trade/${this.state.counter_asset.asset_code}-${this.state.base_asset.asset_code}/${this.state.counter_asset.asset_issuer}/${this.state.base_asset.asset_issuer}`
    //
    //   window.history.replaceState(null,null, `/trade/${this.state.counter_asset.asset_code}-${this.state.base_asset.asset_code}/${this.state.counter_asset.asset_issuer}/${this.state.base_asset.asset_issuer}`);
    //   this.setStateFromUrl()
    // }, 10)

    if (this.state.account) {
      this.getAccount(this.state.account.account_id, true)
    }


    this.setState({
      asset: false,
      orderbook: false,
      trades:[],
      cursor_trades: false,
      stream_orderbook: false,
      stream_trades: false,
      load_toml: false,
      base_volume: 0,
      counter_base_price: 0,
      counter_usd_price: 0,
      counter_change1d_color: '...',
      counter_change1d: '...',
      counter_change7d_color: '...',
      counter_change7d: '...',
      orig_buy_price_orderbook: 0,
      orig_sell_price_orderbook: 0,
      // base_asset: {
      //   asset_code: '...'
      // }
    })
  }



  showModal(type) {

    if (!this.state.assets_db.length) {
      this.getAssetsDB()
    }

    this.setState({
      select_asset_type: type
    })

    this.toggleModal()
  }



  checkAccount(account_id) {
    Server.loadAccount(account_id)
      .then((account) => {
        this.setState({ locked: getLockStatus(account) })
      })
      .catch( (err) => {
        console.log('checkAccount error: ', err);
    })
  }



  sendAssetData = async () => {

    const response = await fetch(`https://api.stellar.expert/explorer/public/asset/?search=${this.state.asset.asset_issuer}&limit=200`);
    const result = await response.json();

    let ex_asset = []
    if (result._embedded && result._embedded.records.length) {

      ex_asset = result._embedded.records.filter(item => {

        const asset_split = item.asset.split('-')
        const asset_str = `${asset_split[0]}-${asset_split[1]}`

        return `${this.state.asset.asset_code}-${this.state.asset.asset_issuer}` === asset_str
      })
    }

    let bids_price = 0, asks_price = 0, spread = 0;
    if (this.state.orderbook) {
      if (this.state.orderbook.bids.length) {
        bids_price = (this.state.orderbook.bids[0].price_r.d / this.state.orderbook.bids[0].price_r.n);
      }
      if (this.state.orderbook.asks.length) {
        asks_price = (this.state.orderbook.asks[0].price_r.d / this.state.orderbook.asks[0].price_r.n);
      }
    }

    spread = (bids_price - asks_price).toFixed(7);
    spread = numFormat(100 / (bids_price / spread), 2);

    const base_volume = this.state.trades_aggregate[0].base_volume
    const price = Number(parseNumber(this.state.counter_base_price)).toFixed(7)

    let asset = {
      asset_code: this.state.asset.asset_code,
      asset_issuer: this.state.asset.asset_issuer,
      supply: this.state.asset.amount,
      holders: this.state.asset.num_accounts,
      base_volume: this.state.trades_aggregate ? base_volume : 0,
      price: price,
      price_usd: Number(price * this.state.price).toFixed(7),
      change24: this.state.trades_aggregate ? this.state.change24 : 0,
      price7d: this.state.change7d,
      change7d: this.state.change7d,
      chart7d: JSON.stringify(change7d(this.state.trades_aggregate).chart7d),
      change_24h_color: this.state.trades_aggregate ? this.state.change_24h_color : 0,
      spread: spread,
      blocked_domain: this.state.blocked_domain,
      // exp
      home_domain: ex_asset.length ? ex_asset[0].domain : this.state.home_domain,
      image: ex_asset.length && ex_asset[0].tomlInfo ? ex_asset[0].tomlInfo.image : this.state.toml ? this.state.toml.image : this.state.counter_asset_db.image ? this.state.counter_asset_db.image : '/img/digitalcoin.png',
      name: ex_asset.length && ex_asset[0].tomlInfo ? ex_asset[0].tomlInfo.name : this.state.toml ? this.state.toml.name : '...',

      expert_rating: ex_asset.length? ex_asset[0].rating.average : 0,
      expert_rating_obj: ex_asset.length ? JSON.stringify(ex_asset[0].rating) : '{}',
      payments: ex_asset.length ? ex_asset[0].payments : 0,
      payments_amount: ex_asset.length ? (ex_asset[0].payments_amount / 10000000) : 0,
      // score: ex_asset.length ? ex_asset[0].score : 0,
      traded_amount: ex_asset.length ? (ex_asset[0].traded_amount / 10000000) : 0,
      volume7d: ex_asset.length ? (ex_asset[0].volume7d / 10000000) : 0,

      // trades: ch_resp.data ? ch_resp.data.data.trades : 0,
      // volume: ch_resp.data ? ch_resp.data.data.volume : 0,
      // volume_usd: ch_resp.data ? ch_resp.data.data.volume_usd : 0,

      trades_chart: JSON.stringify(this.state.trades_aggregate),
      orderbook: JSON.stringify(this.state.orderbook),
      trades: JSON.stringify(this.state.trades),
    }

    // console.log('asset_up: ', asset)


    const formData = new FormData();
    formData.append('asset_code', asset.asset_code)
    formData.append('asset_issuer', asset.asset_issuer)
    formData.append('image', asset.image)
    formData.append('name', asset.name)
    formData.append('home_domain', asset.home_domain)
    formData.append('supply', asset.supply)
    formData.append('base_volume', asset.base_volume)
    formData.append('price', asset.price)
    formData.append('price_usd', asset.price_usd)
    formData.append('price7d', asset.price7d)
    formData.append('change24', asset.change24)
    formData.append('change7d', asset.change7d)
    formData.append('holders', asset.holders)
    formData.append('chart7d', asset.chart7d)
    formData.append('change_24h_color', asset.change_24h_color)
    formData.append('spread', asset.spread)
    formData.append('last_time', this.state.trades.length ? moment(this.state.trades[0].ledger_close_time).format('YYYY-MM-DD HH:mm:ss') : '')
    formData.append('blocked_domain', asset.blocked_domain)

    formData.append('expert_rating', asset.expert_rating)
    formData.append('expert_rating_obj', asset.expert_rating_obj)
    formData.append('payments', asset.payments)
    formData.append('payments_amount', asset.payments_amount)
    formData.append('traded_amount', asset.traded_amount)
    formData.append('volume7d', asset.volume7d)

    formData.append('trades_chart', asset.trades_chart)
    formData.append('orderbook', asset.orderbook)
    formData.append('trades', asset.trades)

    // if (base_volume > 1) {
      this.updateToken(formData, asset)
    // }
  }


  updateToken = (formData, asset) => {

    axios.post(`${GLOBE.API_URL}/update_token`, formData).then(response => {

      if (response) {
        // console.log('up_token: ', response)

        // const locUpToken = localStorage.getItem('up_token');
        // if (!locUpToken) {
        //   localStorage.setItem('up_token', JSON.stringify(asset))
        //   this.sendAssetData()
        // }

        this.setState({ up_token: asset },
          () => {
            localStorage.setItem('up_token', JSON.stringify(asset))

            if (!this.state.counter_asset_db) {
              this.getCounterAssetDB(asset)
            }
          })
      }
    });
  }


  searchAssetForm = (values) => {
    this.searchAsset(values.search_asset)
  }

  searchAssetChange = (value) => {
    this.setState({
      search_asset_code: value.target.value
    })
    this.searchAsset(value.target.value)
  }

  searchAssetClick = () => {
    this.searchAsset(this.state.search_asset_code)
  }

  searchAsset = (assetCode) => {

    if (!assetCode || assetCode === '') {
      this.setState({
        assets_db: [],
        stellar_assets: [],
      })
      this.getAssetsDB()
      ToastStore.warning('Enter the name of the Asset');
    }
    else {

      const url = `${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${assetCode}&asset_issuer=`;
      axios.post(url)
        .then(res => {

          if (res.data.length > 0) {
            this.setState({
              assets_db: res.data,
              loading_stellar_assets: true,
            })

            this.getStellarAssets(assetCode)
          }
          else {
            ToastStore.info('Asset not found');
          }
        })
    }
  }


  getStellarAssets = (assetCode) => {

    Server.assets()
      .forCode(assetCode)
      .limit(200)
      .call()
      .then((result) => {

        let assets = result.records;
        if (assets.length > 0) {

          assets.forEach((item, index) => {
            item.home_domain = item._links.toml.href.replace('https://', '').replace('/.well-known/stellar.toml', '');
          })

          this.setState({
            stellar_assets: assets,
          })
        }
      })
      .catch( (error) => {
        console.log('error: ', error)
      })
  }



  checkWatchlist = (counter_asset) => {

    let local_favorites = localStorage.getItem('favorites');

    if (local_favorites) {
      local_favorites = JSON.parse(local_favorites);

      if (local_favorites.assets.length) {
        let isset_asset = false;
        local_favorites.assets.forEach((item, index) => {
          if (item.asset_code === counter_asset.asset_code && item.asset_issuer === counter_asset.asset_issuer) {
            isset_asset = true;

            if (isset_asset) {
              this.setState({
                star_color: '#ffc200'
              });
            }
          }
        });
      }
    }
  }



  toggleWatchlist = () => {

    const { counter_asset } = this.state;

    const asset = {
      asset_code: counter_asset.asset_code,
      asset_issuer: counter_asset.asset_issuer,
    }

    let local_favorites = localStorage.getItem('favorites');

    if (!local_favorites) {

      let favorites = {
        addresses: [],
        assets: [],
        offers: [],
      }

      favorites.assets.unshift(asset);
      favorites = JSON.stringify(favorites);
      localStorage.setItem('favorites', favorites);

      this.setState({
        favorites_asset: true,
        star_color: '#ffc200'
      });

      ToastStore.success('Asset added to Watchlist');

      this.setWachAssetDB('set', asset);
    }

    else { // isset local

      local_favorites = JSON.parse(local_favorites);

      let isset_asset = false;
      if (local_favorites.assets.length) {
        local_favorites.assets.forEach((item, index) => {
          if (item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer) {
            isset_asset = true;
          }
        });
      }
      else {
        local_favorites.assets = [];
      }

      if (!isset_asset) {

        local_favorites.assets.unshift(asset);
        local_favorites = JSON.stringify(local_favorites);
        localStorage.setItem('favorites', local_favorites);

        this.setState({
          favorites_asset: true,
          star_color: '#ffc200'
        });

        ToastStore.success('Asset added to Watchlist!');

        this.setWachAssetDB('set', asset);
      }
      else {

        local_favorites.assets.forEach((item, index) => {
          if (item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer) {

            local_favorites.assets.splice(index, 1);

            local_favorites = JSON.stringify(local_favorites);
            localStorage.setItem('favorites', local_favorites);
            this.setState({
              favorites_asset: false,
              star_color: '#b1c3c8'
            });

            ToastStore.info('Asset removed from Watchlist');

            this.setWachAssetDB('remove', asset);
          }
        });
      }
    }
  }



  setWachAssetDB(type, asset) {

    let auth_user = localStorage.getItem('auth_user');
    if (auth_user) {

      const formData = new FormData();
      formData.append('user_id', JSON.parse(auth_user).user_id)
      formData.append('asset_code', asset.asset_code)
      formData.append('asset_issuer', asset.asset_issuer)
      formData.append('type', type)

      axios.post(GLOBE.API_URL+`/watch_asset`, formData)
        .then(response => {
          // console.log('response: ', response)
        });
    }
  }



  renderModal(type) {

    const { handleSubmit } = this.props;

    return (
      <Draggable disabled={true}>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={`modal-dialog--primary modal-dialog--header`}
        >
          <div>
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
              <h4 className="bold-text  modal__title">Select {this.state.select_asset_type == 'counter_asset' ? 'Counter' : 'Base'} Asset</h4>
            </div>
            <div className="modal__body modal-scroll">

              <div className="tabs tabs--justify tabs--bordered-bottom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTabAssets === '1' })}
                        onClick={() => {
                          this.toggleAssets('1');
                        }}
                      >
                        <b>All Assets</b>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTabAssets === '2' })}
                        onClick={() => {
                          this.toggleAssets('2');
                        }}
                      >
                        <b>My Assets</b>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTabAssets}>
                    <TabPane tabId="1">

                      <form className="form modal-form" onSubmit={handleSubmit(this.searchAssetForm.bind(this))}>
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <Field
                              name="search_asset"
                              component="input"
                              type="text"
                              placeholder="Asset Code"
                              onChange={this.searchAssetChange}
                            />
                            <button
                              type="button"
                              className={`form__form-group-button btn btn-secondary`}
                              onClick={this.searchAssetClick}
                            ><i className="fa fa-search" aria-hidden="true"></i></button>
                          </div>
                        </div>
                      </form>

                      {
                        this.state.select_asset_type === 'base_asset' && this.state.base_asset.asset_issuer !== 'native' ?
                          <a href={`/trade/${this.state.counter_asset.asset_code}-XLM/${this.state.counter_asset.asset_issuer}/native`}>
                            <Card style={{paddingBottom: 6}}>
                              <CardBody className="item-asset text-secondary">
                                <div className="wr-asset-item-icon">
                                  <img className="asset-item-icon" src={this.state.native_asset.image} />
                                </div>
                                <b className="text-info" style={{fontSize: 15}}>{this.state.native_asset.asset_code}</b> <small><b>{this.state.native_asset.name}</b> / <span className={'text-info'}>{this.state.native_asset.home_domain}</span></small> <span style={{float: 'right'}}> <small className={`text-${this.state.changeColor}`}><b>{this.state.change > 0 ? '+' : ''}{numFormat(this.state.change, 2)}%</b></small></span>
                              </CardBody>
                            </Card>
                          </a>
                          : null
                      }

                      {
                        this.state.select_asset_type === 'counter_asset' ?
                          this.state.assets_db.length ?
                            this.state.assets_db.map((item, index) => {

                              return (
                                <a key={index}
                                   href={item.asset_issuer !== this.state.counter_asset.asset_issuer
                                   && item.asset_issuer !== this.state.base_asset.asset_issuer
                                     ? `/trade/${item.asset_code}-${this.state.base_asset.asset_code}/${item.asset_issuer}/${this.state.base_asset.asset_issuer}`
                                     : '#'}
                                >
                                  <Card style={{paddingBottom: 6}}>
                                    <CardBody className="item-asset text-secondary">
                                      <div className="wr-asset-item-icon">
                                        <img className="asset-item-icon" src={item.image === 'undefined' || item.image === '' ? '/img/digitalcoin.png' : item.image} />
                                      </div>
                                      <b className="text-info" style={{fontSize: 14}}>{item.asset_code}</b> <small><b>{item.name}</b> / <span className={'text-info'}>{item.home_domain}</span></small>
                                      <div>
                                        <span className="text-secondary"><small><i className={'fa fa-clock-o'}></i> <b className={'text-info'}>{numFormat(item.base_volume)}</b> XLM</small> &nbsp;&nbsp; <small><i className={'fa fa-users'}></i> <b className={'text-info'}>{numFormat(item.holders)}</b></small> &nbsp;&nbsp; <small><i className={'fa fa-trophy'}></i> <b className={'text-info'}>{item.expert_rating}</b></small></span>
                                        <span style={{float: 'right'}}><small className={`text-${item.change_24h_color}`}><b>{item.change24}%</b></small></span>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </a>
                              )
                            })
                            : <div className="panel__refresh"><LoadingIcon /></div>
                          : null
                      }

                      {
                        this.state.select_asset_type === 'base_asset' ?
                          this.state.assets_db.length ?
                            this.state.assets_db.map((item, index) => {

                              return (
                                <a key={index}
                                   href={item.asset_issuer !== this.state.base_asset.asset_issuer
                                   && item.asset_issuer !== this.state.counter_asset.asset_issuer
                                     ? `/trade/${this.state.counter_asset.asset_code}-${item.asset_code}/${this.state.counter_asset.asset_issuer}/${item.asset_issuer}`
                                     : '#'}
                                >
                                  <Card style={{paddingBottom: 6}}>
                                    <CardBody className="item-asset text-secondary">
                                      <div className="wr-asset-item-icon">
                                        <img className="asset-item-icon" src={item.image === 'undefined' || item.image === '' ? '/img/digitalcoin.png' : item.image} />
                                      </div>
                                      <b className={'text-info'} style={{fontSize: 14}}>{item.asset_code}</b> <small><b>{item.name}</b> / <span className={'text-info'}>{item.home_domain}</span></small>
                                      <div>
                                        <span className="text-secondary"><small><i className={'fa fa-clock-o'}></i> <b className={'text-info'}>{numFormat(item.base_volume)}</b> XLM</small> &nbsp;&nbsp; <small><i className={'fa fa-users'}></i> <b className={'text-info'}>{numFormat(item.holders)}</b></small> &nbsp;&nbsp; <small><i className={'fa fa-trophy'}></i> <b className={'text-info'}>{item.expert_rating}</b></small></span>
                                        <span style={{float: 'right'}}><small className={`text-${item.change_24h_color}`}><b>{item.change24}%</b></small></span>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </a>
                              )
                            })
                            : <div className="panel__refresh"><LoadingIcon /></div>
                          : null
                      }

                      {
                        this.state.loading_stellar_assets ?
                          <div>
                            <hr/>
                            <h5><b className={'text-secondary'}>Other Stellar Assets:</b></h5><br/>
                            {
                              this.state.stellar_assets ? this.state.stellar_assets.map((item, index) => {

                                if (item.asset_issuer !== this.state.base_asset.asset_issuer) {
                                  return (
                                    <a key={index}
                                       href={
                                         this.state.select_asset_type === 'base_asset' ?
                                           `/trade/${this.state.counter_asset.asset_code}-${item.asset_code}/${this.state.counter_asset.asset_issuer}/${item.asset_issuer}`
                                           :
                                           `/trade/${item.asset_code}-${this.state.base_asset.asset_code}/${item.asset_issuer}/${this.state.base_asset.asset_issuer}`
                                       }
                                    >
                                      <Card style={{paddingBottom: 6}}>
                                        <CardBody className="item-asset text-secondary">
                                          <b style={{fontSize: 14}} className="text-info">{item.asset_code}</b> <small>/ <span className={''}>{item.home_domain}</span> / <i className="fa fa-users" aria-hidden="true"></i> <b>{numFormat(item.num_accounts)}</b>
                                          <span style={{float: 'right'}}> Issued: <b>{numFormat(item.amount)}</b> {item.asset_code}</span></small>
                                        </CardBody>
                                      </Card>
                                    </a>
                                  )
                                }
                              }) : <p>Loading Assets from Stellar  <PulseLoader
                                css={override}
                                size={8}
                                color={"#70bbfd"}
                                loading={true}
                              /></p>
                            }
                          </div>
                          : null
                      }

                      {
                        !this.state.search_asset_code ?
                          <div className="text-center">
                            <Button
                              size="sm"
                              style={{margin: '13px 0 0'}}
                              onClick={() => this.loadMoreAssetsDB()}>Load more</Button>
                          </div>
                          : null
                      }
                    </TabPane>

                    <TabPane tabId="2">
                      {
                        this.state.account ?
                          this.state.account.balances.map((item, index) => {
                            // console.log('item: ', item)

                            if (item.asset_type !== 'liquidity_pool_share') {
                              return (
                                <div key={index}>
                                  {
                                    item.asset_type != 'native' ?
                                      <a key={index}
                                         href={
                                           this.state.select_asset_type === 'base_asset' ?
                                             `/trade/${this.state.counter_asset.asset_code}-${item.asset_code}/${this.state.counter_asset.asset_issuer}/${item.asset_issuer}`
                                             :
                                             `/trade/${item.asset_code}-${this.state.base_asset.asset_code}/${item.asset_issuer}/${this.state.base_asset.asset_issuer}`
                                         }
                                      >
                                        {/*<a href={`/trade/${item.asset_code}-${this.state.base_asset.asset_code}/${item.asset_issuer}/${this.state.base_asset.asset_issuer}`}*/}
                                        {/*>*/}
                                        <Card style={{paddingBottom: 6}}>
                                          <CardBody className="item-asset text-secondary">
                                            <b className="text-warning" style={{fontSize: 14}}>{item.asset_code}</b> <small>(<b className="text-info">{numFormat(item.balance, 7)}</b>)</small> <span style={{float: 'right'}}><small> <font className="text-danger">selling</font> <b className="text-info">{numFormat(item.selling_liabilities, 7)}</b> / <font className="text-success">buying</font> <b className="text-info">{numFormat(item.buying_liabilities, 7)}</b></small></span>
                                          </CardBody>
                                        </Card>
                                      </a>
                                      :
                                      null
                                  }
                                </div>
                              )
                            }
                          })
                          :
                          <p>Select Account to view all Balances (Assets)</p>
                      }
                    </TabPane>
                  </TabContent>
                </div>
              </div>

            </div>
          </div>
        </Modal>
      </Draggable>
    )
  }



  ratingOptions = () => {

    const { counter_asset_db } = this.state;

    const capitalizeFirstLetter = (string) => {
      if (string === 'interop') { string = 'interoperability' }
      if (string === 'volume7d') { string = 'Weekly Volume' }
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const ratingObj = JSON.parse(counter_asset_db.expert_rating_obj)
    delete ratingObj["average"];
    const categories = Object.keys(ratingObj).map(key => `${capitalizeFirstLetter(key)}: ${ratingObj[key]}`);
    const series = Object.values(ratingObj);

    return {
      series: [{
        name: 'Values',
        data: series,
      }],
      options: {
        chart: {
          height: 500,
          type: 'radar',
        },
        title: {
          text: ''
        },
        xaxis: {
          categories: categories
        },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box"><b>'+ w.globals.labels[dataPointIndex] +'</b></div>'
            );
          }
        }
      },
    };
  }



  renderModalRating = () => {

    const { modalRating, counter_asset, counter_asset_db } = this.state;

    let classElem = '';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = 'modal-dark'
    }

    return (
      <Draggable>
        <Modal
          isOpen={modalRating} //
          toggle={this.toggleModalRating}
          className={`modal-dialog--primary modal-dialog--header`}
        >
          <div>
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModalRating} />
              <h4 className="bold-text  modal__title">Rating <b>{counter_asset.asset_code}</b> {counter_asset_db && counter_asset_db.expert_rating}</h4>
            </div>
            <div className={`modal__body text-center ${classElem}`}>
              <div>Composite rating based on asset age, established trustlines, weekly trading volume, payments and trades count.</div>
              {
                counter_asset_db && modalRating &&
                <Chart
                  options={this.ratingOptions().options}
                  series={this.ratingOptions().series}
                  type="radar"
                  width="500"
                  style={isMobileOnly ? {margin: '-23px -99px -35px'} : {margin: '-23px 0 -35px'}}
                />
              }
              <div>The asset rating is determined based on data from <b>stellar.expert</b></div>
              <br/>
            </div>
          </div>
        </Modal>
      </Draggable>
    )
  }



  renderPair(item, index) {
    return (
      <a key={index} href={`/trade/${item.buy_asset.asset_code}-${item.sell_asset.asset_code}/${item.buy_asset.asset_issuer}/${item.sell_asset.asset_issuer}`}
         className="ex_pair"><span><b className="text-secondary">{item.buy_asset.asset_code}</b>
        <b className="text-secondary">/</b><b className="text-secondary">{item.sell_asset.asset_code}</b></span>
      </a>
    )
  }


  changeLimitOrder = (e, limit, type) => {
    e.preventDefault()

    let limit_price = 0

    if (type === 'buy') {
      limit_price = Number(this.state.orig_buy_price_orderbook - (this.state.orig_buy_price_orderbook * limit / 100)).toFixed(7)
      this.props.change('buy_price', Number(limit_price))
    }

    if (type === 'sell') {
      limit_price = Number(this.state.orig_sell_price_orderbook + (this.state.orig_sell_price_orderbook * limit / 100)).toFixed(7)
      this.props.change('sell_price', Number(limit_price))
    }

    this.setTotal(limit_price, type)
  }


  reversePair = (e) => {
    e.preventDefault()
    const { counter_asset, base_asset } = this.state

    if (base_asset.asset_issuer !== 'native') {
      const url = `${origin}/trade/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`
      window.location.href = url
    }
  }



  render() {

    // console.log('Trade state: ', this.state)

    const { reset, handleSubmit } = this.props;
    const { asset, counter_asset, base_asset, blocked_domain, counter_asset_db, counter_base_price, counter_usd_price, counter_change1d_color,
      counter_change1d, base_volume, home_domain, progress_price, color_price,  } = this.state
    // const asset_db = this.state.assets_db.filter(item => item.asset_code === this.state.asset.asset_code && item.asset_issuer === this.state.asset.asset_issuer)
    const accept_asset = {...asset, ...counter_asset_db}


    if (this.state.redirect_wallet) {
      return (
        <Redirect
              to={{ pathname: "/auth/create" }}
            />
      )
    }

    let market_cap_usd = false, usd_volume;
    if (this.state.asset && this.state.asset.amount && this.state.counter_base_price && this.state.price) {
      market_cap_usd = ((this.state.asset.amount * this.state.counter_base_price) * this.state.price);
      usd_volume = (this.state.base_volume * this.state.price)

      if (base_asset.asset_code !== 'XLM' && base_asset.asset_issuer !== 'native') {
        const base_cap_price = (this.state.counter_xlm_price_for_cap ? this.state.counter_xlm_price_for_cap.price.d / this.state.counter_xlm_price_for_cap.price.n : 0);
        market_cap_usd = ((this.state.asset.amount * base_cap_price) * this.state.price);
        usd_volume = ((this.state.base_volume * this.state.base_xlm_price_for_cap) * this.state.price)
      }
    }

    return (
      <Container className="trade" style={{marginTop: '-21px'}}>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />
        {
          // this.toastDismiss()
        }

        <div>
          <NotificationSystem ref={this.notificationSystem} />
        </div>

        { this.renderModal() }
        { this.renderModalRating() }

        {
          this.state.show_accept_modal &&
            <AcceptAsset
              asset={accept_asset}
              toggleAcceptModal={this.toggleAcceptModal}
              wallets={this.state.wallets}/>
        }


        <Row className="wr-row text-center text-secondary">

          <Col md={5} lg={3} className="trade-col">
            <Card style={{height: 'auto'}}>
              <CardBody align="center">

                <Row>
                  <Col md={5} xs={5} sm={5} style={{paddingRight: 0}}>
                    <a className="asset-item"
                       href="#"
                       onClick={() => this.showModal('counter_asset')}>
                      <div>
                        <div className="wr-asset-icon">
                          <img className="asset-icon" src={this.state.counter_image} />
                        </div>
                        <div className="wr-asset-code">
                          <div className="asset-code">{counter_asset.asset_code}</div>
                          <div className="asset-name">{!this.state.counter_name || this.state.counter_name === 'undefined' ? counter_asset.asset_code : this.state.counter_name }</div>
                          <div className="asset-domain">{this.state.counter_domain}</div>
                        </div>
                      </div>
                    </a>
                  </Col>
                  <Col md={2} xs={2} sm={2}>
                    <p style={{marginTop: 12}}>
                      <a href="#"
                         onClick={(e) => this.reversePair(e)}><i className="fa fa-exchange text-secondary"></i></a>
                    </p>
                  </Col>
                  <Col md={5} xs={5} sm={5} style={{paddingLeft: 0}}>
                    <a className="asset-item"
                       href="#"
                       onClick={() => this.showModal('base_asset')}>
                      <div>
                        <div className="wr-asset-icon">
                          <img className="asset-icon" src={this.state.base_image} />
                        </div>
                        <div className="wr-asset-code">
                          <div className="asset-code">{this.state.base_asset.asset_code}</div>
                          <div className="asset-name">{!this.state.base_name || this.state.base_name === 'undefined' ? this.state.base_asset.asset_code : this.state.base_name }</div>
                          <div className="asset-domain">{this.state.base_domain}</div>
                        </div>
                      </div>
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={7} lg={3} className="trade-col">
            <Card>
              <CardBody>

                {
                  this.state.trades_aggregate ?
                    <Row className="">
                      <Col md={7} xs={7} sm={7} style={{paddingRight: 0}}>
                        <div className="transform-txt">PRICE</div>
                        <span><b className={`top-price ${this.state.type_trade_class}`}><i className={this.state.type_trade_class === 'text-success' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'}></i>{' '}
                          {numFormat(this.state.counter_base_price, 7)}</b> <small className="text-secondary">{this.state.base_asset.asset_code}</small></span>
                        <div><small><b>${numFormat(this.state.counter_usd_price, 7)}</b></small></div>
                      </Col>
                      <Col md={5} xs={5} sm={5} className="">
                        <div><font className="text-secondary"><small>24H</small> <b className={`top-price text-${this.state.counter_change1d_color}`}>{this.state.counter_change1d}%</b> </font></div>
                        <div><small className="text-secondary"><font>7D</font> <b className={`text-${this.state.counter_change7d_color}`}>{this.state.counter_change7d}%</b></small></div>
                      </Col>
                    </Row>
                  :
                    this.state.isTrades && this.state.isTradesAggregate ?
                      <div className="panel__refresh"><LoadingIcon /></div>
                      : <div className={'not-history'}>{this.noTradeHistory}</div>
                }

              </CardBody>
            </Card>
          </Col>

          <Col md={5} lg={3} className="trade-col">
            <Card>
              <CardBody>

                {
                  this.state.trades_aggregate ?

                    <Row>
                      <Col md={8} xs={8} sm={8}>
                        <div className="transform-txt">VOL24</div>
                        <small><span style={{paddingTop: 5, fontSize: 14}}><b className="text-info">${numFormat(usd_volume)}</b> </span></small><br/>
                        <small className="text-secondary"><b>{numFormat(this.state.base_volume)}</b> {this.state.base_asset.asset_code}</small>
                      </Col>
                      <Col md={4} xs={4} sm={4} style={{paddingLeft: 0, borderLeft: '1px solid #5c5b5b85'}}>
                        <div className="text-secondary"><small><b>Spread</b></small></div>
                        <div><small className="text-info"><b>{this.state.spread}%</b></small></div>
                      </Col>
                    </Row>
                  :
                    this.state.isTrades && this.state.isTradesAggregate ?
                      <div className="panel__refresh"><LoadingIcon /></div>
                      : <div className={'not-history'}>{this.noTradeHistory}</div>
                }

              </CardBody>
            </Card>
          </Col>

          <Col md={7} lg={3} className="trade-col">
            <Card>
              <CardBody>

                {
                  this.state.trades_aggregate ?
                    <div>
                      <Row className="low-high-open">
                        <Col md={4} xs={4} sm={4}>
                          <div className="text-secondary"><small><b>Low</b></small></div>
                          <div><small><b className="text-info">{numFormat(this.state.price_low, 7)}</b></small></div>
                        </Col>
                        <Col md={4} xs={4} sm={4} >
                          <div className="text-secondary"><small><b>Close</b></small></div>
                          <div><small><b className="text-info">{numFormat((this.state.price_close), 7)}</b></small></div>
                        </Col>
                        <Col md={4} xs={4} sm={4} >
                          <div className="text-secondary"><small><b>High</b></small></div>
                          <div><small><b className="text-info">{numFormat(this.state.price_high, 7)}</b></small></div>
                        </Col>
                      </Row>
                      <div className={`progress-wrap progress-wrap--${color_price}`}
                           style={{padding: '0 7px 0 7px', margin: '3px 0 -6px 0'}}>
                        <Progress value={progress_price} color={color_price} />
                      </div>
                    </div>
                    :
                    this.state.isTrades && this.state.isTradesAggregate ?
                      <div className="panel__refresh"><LoadingIcon /></div>
                      : <div className={'not-history'}>{this.noTradeHistory}</div>
                }

              </CardBody>
            </Card>
          </Col>

        </Row>


        {/*<Row className="wr-row">
          <Col md={3} lg={3} className="trade-col">
          <Card>
            <CardBody>
              <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTabLeft === '1' })}
                          onClick={() => {
                            this.toggleTabLeft('1');
                          }}
                        >
                          <b>Summary</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTabLeft === '2' })}
                          onClick={() => {
                            this.toggleTabLeft('2');
                          }}
                        >
                          <b>Trade</b>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTabLeft}>
                      <TabPane tabId="1">
                        фывафывафыва1
                      </TabPane>
                      <TabPane tabId="2">
                        фывафывафыва2
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
            </CardBody>
          </Card>
          </Col>
          <Col md={9} lg={9} className="trade-col">
            <Card>
              <CardBody>
                аыфвафыва
              </CardBody>
            </Card>
          </Col>
        </Row>*/}



        <Row className="wr-row">

          <Col md={5} lg={3} className="trade-col">

            <Card>
              <CardBody>

                <div className="card__title" style={{marginBottom: 19}}>
                  <h5 className="bold-text">Asset</h5>
                </div>
                <div className="panel__btns">
                  {
                    this.scop.code === counter_asset.asset_code && this.scop.issuer === counter_asset.asset_issuer ?
                      <div>
                        <Link to={`/rewards`}><div className={'rewards pool-rewards pool-color-scop'}>Get <b>+{numFormat(this.apy_scop)}% SCOP <i className={'fa fa-question-circle-o'}></i></b></div></Link>
                      </div>
                      : null
                  }

                  {
                    blocked_domain ?
                      <span>
                        <Popover
                          placement="top"
                          isOpen={this.state.popoverBlockOpen}
                          target="PopoverBlockDomain"
                          trigger="legacy"
                          toggle={this.togglePopoverBlockDomain}
                        >
                          <PopoverBody>
                           <span className={'text-danger'}>
                             <i className="fa fa-exclamation-triangle"></i> <b>Warning:</b>
                           </span> <b className={'text-secondary'}>reported for illicit or fraudulent activity.</b>  <br/><br/>
                            <span>This domain is on the list of blocked domains reported by the community for fraudulent activity.</span><br/><br/>
                            <span>This alert receives data from <a href="https://stellar.expert/directory/blocked-domains" target='_blank' rel='noopener noreferrer'>stellar.expert</a></span>
                          </PopoverBody>
                        </Popover>

                        <button id={'PopoverBlockDomain'} className={'panel__btn text-danger'}
                                onClick={this.togglePopoverBlockDomain}
                        ><i className="fa fa-exclamation-triangle"></i></button>
                      </span>
                    : null
                  }
                  <button className="panel__btn text-secondary" type="button" onClick={this.toggleWatchlist}>
                    <i className={'fa fa-star-o'} style={{color: this.state.star_color, fontSize: '15px'}}></i>
                  </button>
                  <button className="panel__btn" type="button" onClick={this.refreshSummary}><AutorenewIcon /></button>
                </div>

                {
                  counter_asset_db.scam === '1' ?
                    <img src="/img/scam.png"
                         className={'scam'}
                         alt=""/>
                    : null
                }

                {this.state.refresh_trades_aggregate ? <div className="panel__refresh" style={{height: '98%'}}><LoadingIcon /></div> : ''}

                <div className="profile__activity" style={{padding: '0 0 12px'}}>
                  <div className="profile__activity-avatar">
                    <img className="icon-asset" src={ this.state.counter_image } alt="" />
                  </div>
                  <div>
                    <h4 className="profile__activity-code">{counter_asset.asset_code}
                      <div className="profile__activity-date" style={{margin: '-8px 0 4px 0', display: counter_asset.asset_code.length > 8 ? 'block' : 'inline'}}>&nbsp; {this.state.counter_name.length > 22 ? shortAddr(this.state.counter_name, 10) : this.state.counter_name }</div>
                    </h4>
                    <Link className="text-secondary"
                          to={`/account/${counter_asset.asset_issuer}`}
                          style={{fontSize: 11}}>{iconIdent(counter_asset.asset_issuer, 'icon-indent-sm')}{' '}
                      {shortAddr(counter_asset.asset_issuer, 8)}&nbsp;&nbsp;
                      <span>
                        {this.state.locked ? <i className="fa fa-lock text-success"></i> : <i className="fa fa-unlock-alt text-info"></i>}
                      </span>
                    </Link>
                    {/*{*/}
                    {/*  this.state.load_toml ?*/}
                    {/*    <div className={''}>*/}
                    {/*      <a className=""*/}
                    {/*         href={`https://${this.state.home_domain}`}*/}
                    {/*         target='_blank' rel='noopener noreferrer'*/}
                    {/*      >{this.state.home_domain}</a>*/}
                    {/*    </div>*/}
                    {/*    : ''*/}
                    {/*}*/}
                  </div>
                  <div>
                    <button className="btn btn-outline-secondary btn-sm accept-btn"
                            onClick={() => this.toggleAcceptModal(true)}
                            disabled={this.state.asset && this.state.asset.asset_code ? false : true}
                      ><i className="fa fa-plus"></i></button>
                  </div>
                  {/*<a className="icon-favorit" onClick={() => this.toggleWatchlist()}>
                    <span className="sidebar__link-icon lnr lnr-star"
                          style={{color: this.state.star_color, fontSize: '16px'}}/>
                  </a>*/}
                </div>


                <Table className="table--bordered asset-info" responsive>
                  <tbody>
                    <tr>
                      <td>PRICE {base_asset.asset_code} </td>
                      <td>
                        <b className={`text-info`}>{numFormat(counter_base_price, 7)}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>PRICE $</td>
                      <td>
                        {/*base_xlm_price_for_cap*/}
                        <span className="text-secondary">$<b>{this.state.trades ? numFormat(counter_usd_price, 7) : '...'}</b></span>
                      </td>
                    </tr>
                    <tr>
                      <td>CHANGE 24</td>
                      <td><b className="text-info"><Badge color={counter_change1d_color}>{counter_change1d}%</Badge></b></td>
                    </tr>
                    <tr>
                      <td>VOL24 {base_asset.asset_code}</td>
                      <td>
                        <b className="text-info">{numFormat(base_volume)}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>VOL24 USD</td>
                      <td>
                        <b className="text-secondary">${numFormat((usd_volume))}</b>
                      </td>
                    </tr>
                    {/*<tr>
                      <td>VOL24 {this.state.counter_asset.asset_code}</td>
                      <td>
                        <b className="text-info">{this.state.trades_aggregate ? numFormat(this.state.counter_volume, 4) : '...'}</b>
                      </td>
                    </tr>*/}
                    <tr>
                      <td>SUPPLY</td>
                      <td><small className="text-secondary">{counter_asset.asset_code}</small> <b className="text-info">{asset ? numFormat(asset.amount, 0) : '...'}</b></td>
                    </tr>
                    <tr>
                      <td>MARKETCAP {' '}
                        <i id="popover-cap" onClick={this.togglePopoverCap} className="fa fa-question-circle-o"></i> {' '}

                        <Popover
                          placement="top"
                          isOpen={this.state.popoverCapOpen}
                          target="popover-cap"
                          trigger="legacy"
                          toggle={this.togglePopoverCap}
                        >
                          <PopoverBody>
                            This is not an accurate estimate of the token capitalization. A more accurate estimate of capitalization
                            should be calculated by multiplying the number of tokens held by holders by the market price. It is almost
                            impossible to find out how many tokens on hand, and now the total number of tokens issued to the market is
                            multiplied by the current price. <i className="fa fa-exclamation-triangle"></i> Be careful, the real capitalization of an asset can be 10 or 100 or more times lower.
                          </PopoverBody>
                        </Popover>
                      </td>
                      <td>&asymp; <b className="text-info">${market_cap_usd ? numFormat(market_cap_usd) : '...'}</b></td>
                    </tr>
                    {/*<tr>
                      <td>TOTAL VOLUME <small>(XLM)</small></td>
                      <td><b className="text-info">{numFormat(this.props.state.asset_db.overall_trade_volume, 0)}</b></td>
                    </tr>
                    <tr>
                      <td>TRADES COUNT</td>
                      <td><b className="text-info">{numFormat(this.props.state.asset_db.total_trades_count, 0)}</b></td>
                    </tr>*/}
                    <tr>
                      <td>
                        <ButtonGroup className="btn-group--icons" dir="ltr">
                          <Button onClick={this.toggleModalRating} outline className={'rating-asset'}>RATING</Button>
                        </ButtonGroup> {' '}
                        <i id="PopoverTop" onClick={this.togglePopoverQuery} className="fa fa-question-circle-o"></i> {' '}

                        <Popover
                          placement="top"
                          isOpen={this.state.popoverQueryOpen}
                          target="PopoverTop"
                          trigger="legacy"
                          toggle={this.togglePopoverQuery}
                        >
                          <PopoverBody>
                            Composite rating based on asset age, established trustlines, weekly trading volume, payments and trades count. <br/> The asset rating is determined based on data from <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a>
                          </PopoverBody>
                        </Popover>
                      </td>
                      <td>
                        <b className="text-info">{counter_asset_db ? numFormat(counter_asset_db.expert_rating, 1) : '...'}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>HOLDERS</td>
                      <td><b className="text-info">{numFormat(asset && asset.num_accounts, 0)}</b></td>
                    </tr>
                    {/*<tr>*/}
                    {/*  <td><i className="fa fa-globe"></i></td>*/}
                    {/*  <td>*/}
                    {/*    {*/}
                    {/*      this.state.load_toml ?*/}
                    {/*          <b className={''}>*/}
                    {/*              <a className=""*/}
                    {/*                href={`https://${this.state.home_domain}`}*/}
                    {/*                target='_blank' rel='noopener noreferrer'*/}
                    {/*              >{this.state.home_domain}</a>*/}
                    {/*          </b> : '...'*/}
                    {/*    }*/}

                    {/*  </td>*/}
                    {/*</tr>*/}
                  </tbody>
                </Table>

                <div className={'domain text-secondary'}>
                  <span>DOMAIN</span>
                    <b className={'text-info'} style={{float: 'right'}}>
                      {
                        home_domain ?
                          <a className="text-info"
                             href={`https://${home_domain}`}
                             target='_blank' rel='noopener noreferrer'
                          >{home_domain}</a>
                        : '...'
                      }
                    </b>
                </div>

              </CardBody>
            </Card>

          </Col>


          <Col md={7} lg={9} className="trade-col right-last">
            <Card>
              <CardBody className="chart-body">

                <div className="card__title" style={{marginBottom: 12}}>
                  <h5 className="bold-text">Charts {' '}
                    <Link to={`/asset/${counter_asset.asset_code}-${counter_asset.asset_issuer}`} className="text-warning" href="#"><b>{counter_asset.asset_code}</b></Link>&nbsp;/&nbsp;
                    <Link to={`/asset/${base_asset.asset_code}-${base_asset.asset_issuer}`}><b className="text-warning">{base_asset.asset_code}</b></Link>&nbsp;
                  </h5>
                </div>
                <div className="panel__btns">
                  <button className="panel__btn" type="button" onClick={() => this.refreshTradeAggregate()}><AutorenewIcon /></button>
                </div>

                {this.state.refresh_trades_aggregate && <div className="panel__refresh"><LoadingIcon /></div>}

                { this.state.trades_aggregate && counter_asset_db  ?
                  <AssetChart trade_agregation={this.state.trades_aggregate}
                              orderbook={this.state.orderbook}
                              buy_asset={this.state.counter_asset}
                              sell_asset={this.state.base_asset}
                              asset_db={counter_asset_db}
                              price={this.state.price}
                              point={'trade'}/>
                  :
                    this.state.isTrades && this.state.isTradesAggregate ?
                      <div className="panel__refresh"><LoadingIcon /></div>
                      : <div className={'not-history'}>{this.noTradeHistory}</div>
                }
              </CardBody>
            </Card>
          </Col>

          {/*<Col md={3} lg={3} className="trade-col">

            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Chat</h5>
                </div>
                <div className="panel__btns">
                  <button className="panel__btn" type="button" onClick={this.onRefresh}><AutorenewIcon /></button>
                </div>

                {this.state.refresh ? <div className="panel__refresh"><LoadingIcon /></div> : ''}

                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
              </CardBody>
            </Card>

          </Col>*/}
        </Row>



        <Row className="wr-row">

          <Col md={5} lg={3} className="trade-col">

            <Card style={{height: '100%'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text anim">Trade</h5>
                </div>

                <div className="panel__btns">
                  <button className="panel__btn" type="button" onClick={() => this.refreshAccount()}><AutorenewIcon /></button>
                </div>

                { this.state.loading_account ? <div className="panel__refresh" style={{height: '47%'}}><LoadingIcon /></div> : null }

                <form className="material-form" onClick={() => {this.clickSelectAddress()}}>

                  {this.renderTradeAddress()}

                </form>
                {
                  this.state.account && this.state.wallets ?
                    <div style={{marginTop: -8}}>
                      <div><small>Relevant Balances:</small></div>
                      <div><small><b className="text-info">{numFormat(this.state.native_balance, 7)}</b> <b className="text-warning">XLM</b></small></div>
                      <div>
                        <small><b className="text-info">{numFormat(this.state.counter_balance, 7)}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b></small>
                        {
                          this.state.trust_base ?
                            <small> / <b className="text-info">{numFormat(this.state.base_balance, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b></small>
                            :
                            this.state.base_asset.asset_issuer !== 'native' &&
                              <small> / No Trustline to <b className="text-warning">{this.state.base_asset.asset_code}</b> {' '}
                                <Button className={'accept-token-sm-btn'}
                                        onClick={() => this.noTrustlineMessage('base')}><i className={'fa fa-plus'}></i></Button>
                              </small>
                        }
                      </div>
                      <div style={{fontSize: 11, marginTop: 4}}><small>Avaliable: <b className="text-info">{numFormat(this.state.available_balance, 7)}</b> <b className="text-warning">XLM</b> &nbsp;/&nbsp; <font>Reserve: <b className="text-info">{this.state.reserve_balance}</b> <b className="text-warning">XLM</b></font></small></div>
                    </div>
                   :
                  <div style={{padding: '20px 0px 38px'}}>
                    <small className="text-secondary">Log in to Stellar Account {' '} <i className="fa fa-hand-o-up"></i></small>
                  </div>
                }

                <div className="card__title">
                  <h5 className="bold-text">Buy / Sell <b className="text-warning">{this.state.counter_asset.asset_code}</b> for <b className="text-warning">{this.state.base_asset.asset_code}</b></h5>
                </div>

                <div className="panel__btns">
                  <button className="panel__btn" type="button" onClick={() => this.refreshBuySell()}><AutorenewIcon /></button>
                </div>

                { this.state.buy_sell_loader ? <div className="panel__refresh" style={{height: '96%'}}><LoadingIcon /></div> : null }

                <Row style={{marginTop: -5}} className={'limit-form'}>
                  <Col md={6} xs={6} sm={6}>
                    <form className="form trade-form" onSubmit={handleSubmit(this.buySubmitForm)} align="center">
                      <div className="form__form-group">
                        <small className="price-label">
                          <CopyToClipboard text={this.state.orig_buy_price_orderbook} onCopy={() => {}}>
                            <a className="text-success"
                               href="#"
                               onClick={(e) => e.preventDefault()}>
                              Ask: {numFormat(this.state.orig_buy_price_orderbook, 7)} {' '}
                              <i className="fa fa-clone"
                                 color="secondary"
                                 style={{cursor: 'pointer'}}
                                 onClick={() => ToastStore.success(`Price: ${this.state.orig_buy_price_orderbook} сopied`)}></i>
                            </a>
                          </CopyToClipboard>
                        </small>
                        <div className="form__form-group-field">
                          <Field
                            name="buy_price"
                            component="input"
                            type="text"
                            placeholder={`Price ${this.state.base_asset.asset_code}`}
                            onChange={(value) => this.priceChange(value, 'buy')}
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="buy_amount"
                            component="input"
                            type="text"
                            placeholder={`Amount ${this.state.counter_asset.asset_code}`}
                            onChange={(value) => this.amountChange(value, 'buy')}
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="buy_total"
                            component="input"
                            type="text"
                            placeholder={`Total ${this.state.base_asset.asset_code}`}
                            onChange={(value) => this.totalChange(value, 'buy')}
                          />
                        </div>
                      </div>

                      <div className={'limit-order limit-order-buy'}>
                        <a href="#" onClick={(e) => this.changeLimitOrder(e, 25, 'buy')}><div><small>25</small></div></a>
                        <a href="#" onClick={(e) => this.changeLimitOrder(e, 50, 'buy')}><div><small>50</small></div></a>
                        <a href="#" onClick={(e) => this.changeLimitOrder(e, 75, 'buy')}><div><small>75</small></div></a>
                        <a href="#" onClick={(e) => this.changeLimitOrder(e, 100, 'buy')}><div><small>100</small></div></a>
                      </div>

                      <ButtonToolbar className="form__button-toolbar w-100">
                        <Button color="success" size="sm" type="submit">BUY</Button>
                      </ButtonToolbar>
                    </form>
                  </Col>
                  <Col md={6} xs={6} sm={6}>
                    <form className="form trade-form" onSubmit={handleSubmit(this.sellSubmitForm)} align="center">
                      <div className="form__form-group">
                        <small className="price-label">
                          <CopyToClipboard text={this.state.orig_sell_price_orderbook} onCopy={() => {}}>
                            <a className="text-danger"
                               href="#"
                               onClick={(e) => e.preventDefault()}>
                              Bid: {numFormat(this.state.orig_sell_price_orderbook, 7)} {' '}
                              <i className="fa fa-clone"
                                 color="secondary"
                                 style={{cursor: 'pointer'}}
                                 onClick={() => ToastStore.success(`Price: ${this.state.orig_sell_price_orderbook} сopied`)}></i>
                            </a>
                          </CopyToClipboard>
                        </small>
                        <div className="form__form-group-field">
                          <Field
                            name="sell_price"
                            component="input"
                            type="text"
                            placeholder={`Price ${this.state.base_asset.asset_code}`}
                            onChange={(value) => this.priceChange(value, 'sell')}
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="sell_amount"
                            component="input"
                            type="text"
                            placeholder={`Amount ${this.state.counter_asset.asset_code}`}
                            onChange={(value) => this.amountChange(value, 'sell')}
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="sell_total"
                            component="input"
                            type="text"
                            placeholder={`Total ${this.state.base_asset.asset_code}`}
                            onChange={(value) => this.totalChange(value, 'sell')}
                          />
                        </div>
                      </div>

                      <div className={'limit-order limit-order-sell'}>
                        <a href="#" onClick={(e) => this.changeLimitOrder(e, 25, 'sell')}><div><small>25</small></div></a>
                        <a href="#" onClick={(e) => this.changeLimitOrder(e, 50, 'sell')}><div><small>50</small></div></a>
                        <a href="#" onClick={(e) => this.changeLimitOrder(e, 75, 'sell')}><div><small>75</small></div></a>
                        <a href="#" onClick={(e) => this.changeLimitOrder(e, 100, 'sell')}><div><small>100</small></div></a>
                      </div>

                      <ButtonToolbar className="form__button-toolbar w-100">
                        <Button color="danger" size="sm" type="submit" >SELL</Button>
                      </ButtonToolbar>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>

          </Col>


          <Col md={7} lg={6} className="trade-col">
            <Card>
              <CardBody className="panel__body">

                <div className="card__title" style={{marginBottom: 8}}>
                  <h5 className="bold-text">Orderbook</h5>
                  {/*<h5 className="subhead">PRICE <b className="text-warning">{this.state.counter_asset.asset_code}</b>: <b className={`text-info ${this.state.type_trade_class}`}>{numFormat(this.state.counter_base_price, 7)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b> <font>(${numFormat(this.state.counter_usd_price, 5)})</font> <span style={{float: 'right'}}>{this.state.trades_aggregate ? <font><b className={`text-${this.state.counter_change1d_color}`}>{this.state.counter_change1d}%</b> 24H / <b className={`text-${this.state.counter_change7d_color}`}>{this.state.counter_change7d}%</b> 7D</font> : '...'}</span> </h5>*/}
                </div>

                <Row style={{margin: '-8px 0 6px 0'}}>
                  <Col md={6} lg={6} xl={6}>
                    <div className="orderbook-res-buy">
                      <h5 className="subhead">ASKS (<b>{this.state.orderbook ? this.state.orderbook.asks.length : 0}</b>): <b className="text-info">{numFormat(this.state.buy_counter_amount)}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b> / <span><b className="text-info">{numFormat(this.state.buy_base_amount)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b></span></h5>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xl={6}>
                    <div className="orderbook-res-sel">
                      <h5 className="subhead">BIDS (<b>{this.state.orderbook ? this.state.orderbook.bids.length : 0}</b>): <b className="text-info">{numFormat(this.state.sell_counter_amount)}</b> <b className="text-warning">{this.state.counter_asset.asset_code}</b> / <span><b className="text-info">{numFormat(this.state.sell_base_amount)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b></span></h5>
                    </div>
                  </Col>
                </Row>

                {
                  !this.state.orderbook ?
                    this.state.isTrades ?
                      <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div>
                      : null
                  : null
                }


                <div className="panel__btns">
                  <small className={'text-secondary'}>
                    {/*<span className={`text-${this.state.counter_change1d_color}`}>{this.state.counter_change1d}%</span>&nbsp;&nbsp;*/}
                    spread: <b>{this.state.spread}%</b>&nbsp;&nbsp;
                    <span className={this.state.type_trade_class}><i className={this.state.type_trade_class === 'text-success' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'}></i> {' '}
                      <b className={this.state.type_trade_class}>{numFormat(this.state.counter_base_price, 5)}</b>
                    </span> {' '}
                    <span style={{fontSize: 8}}>{this.state.base_asset.asset_code}</span>
                  </small>
                  <button className="panel__btn" type="button" onClick={() => this.refreshOrderbook()}><AutorenewIcon /></button>
                </div>

                <Row className="orderbook-table">
                  <Col md={6} lg={6} xl={6} style={{paddingRight: 5}}>
                    <Card>
                      <Table striped responsive hover className="text-right buy-offers">
                        <thead>
                          <tr>
                            <th><small>Depth</small></th>
                            <th>{this.state.base_asset.asset_code}</th>
                            <th>{this.state.counter_asset.asset_code}</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody style={{fontSize: 10}}>
                          {
                            this.state.orderbook ? this.renderBuyOffers(this.props) : null
                          }
                        </tbody>
                      </Table>
                    </Card>
                  </Col>

                  <Col md={6} lg={6} xl={6} style={{paddingLeft: 5}}>
                    <Card>

                      <Table striped responsive hover className="sell-offers">
                        <thead>
                          <tr>
                            <th>Price</th>
                            <th>{this.state.counter_asset.asset_code}</th>
                            <th>{this.state.base_asset.asset_code}</th>
                            <th><small>Depth</small></th>
                          </tr>
                        </thead>
                        <tbody style={{fontSize: 10}}>
                          {
                            this.state.orderbook ? this.renderSellOffers(this.props) : null
                          }
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>

          <Col md={12} lg={3} className="trade-col right-last">

            <Card>
              <CardBody className="sm-table">

                <div className="card__title" style={{marginBottom: 8}}>
                  <h5 className="bold-text">Trades</h5>
                  {/*<h5 className="subhead">VOL24: <b className="text-info">{numFormat(this.state.base_volume, 2)}</b> <b className="text-warning">{this.state.base_asset.asset_code}</b> <small> / $<b>{numFormat(usd_volume, 2)}</b></small></h5>*/}
                </div>
                <div className="panel__btns">
                  <button className="panel__btn" type="button" onClick={() => this.refreshTrades()}><AutorenewIcon /></button>
                </div>

                <Table className="table-head">
                  <thead>
                      <tr>
                        <th>Price</th>
                        <th>{this.state.counter_asset.asset_code}</th>
                        <th>{this.state.base_asset.asset_code}</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                </Table>

                <div className="trades-block">
                  <Table responsive hover>
                    <tbody style={{fontSize: 10}}>
                      {
                          this.state.trades.length ?

                            this.state.trades.map((item, index) => {
                              return (
                                <tr key={index} id={`popover-${index}`} className={`${item.base_is_seller ? 'text-success' : 'text-danger'}`} onClick={() => {this.togglePopover(item, index)}}>
                                    <td>{numFormat((item.price.d / item.price.n), 7)}</td>
                                    <td>{numFormat(item.counter_amount, 6)}</td>
                                    <td>{numFormat(item.base_amount, 6)}</td>
                                    <td><small>{format(item.ledger_close_time, 'en_US')}</small></td>
                                </tr>
                              )
                            })
                          :
                            this.state.isTrades ?
                              <div className="panel__refresh"><LoadingIcon /></div>
                              : null
                        }
                    </tbody>
                  </Table>
                </div>

                  {!this.state.trades ? <div className="panel__refresh"><LoadingIcon /></div> : null}

                  {
                    this.state.popover_trade && this.state.popoverOpen ?
                        <Popover
                          placement="top"
                          isOpen={this.state.popoverOpen}
                          target={`popover-${this.state.popover_id}`}
                          trigger="legacy"
                          toggle={this.togglePopover(this.state.popover_id)}>
                            <PopoverHeader>Trade <span style={{float: 'right'}}>offer: <Link to={`/offer/${this.state.popover_trade.counter_offer_id}`}>{shortAddr(this.state.popover_trade.counter_offer_id, 3)}</Link></span></PopoverHeader>
                            <PopoverBody>
                              {
                                this.state.popover_trade.base_is_seller ?
                                  <span>{this.checkMyAccount(this.state.popover_trade.counter_account) ? 'My Account' : ''} {shortAddress(this.state.popover_trade.counter_account)} <span className="text-danger">sold</span> <b className="text-info">{numFormat(this.state.popover_trade.counter_amount, 7)}</b> { getCounterAsset(this.state.popover_trade) } / <font className="text-success">bought</font> <b className="text-info">{numFormat(this.state.popover_trade.base_amount, 7)}</b> { getBaseAsset(this.state.popover_trade) } from {this.checkMyAccount(this.state.popover_trade.base_account) ? 'My Account' : ''} { shortAddress(this.state.popover_trade.base_account) } </span>
                                :
                                  <span>{this.checkMyAccount(this.state.popover_trade.base_account) ? 'My Account' : ''} {shortAddress(this.state.popover_trade.base_account)} <span className="text-success">bought</span> <b className="text-info">{numFormat(this.state.popover_trade.counter_amount, 7)}</b> { getCounterAsset(this.state.popover_trade) } / <font className="text-danger">sold</font> <b className="text-info">{numFormat(this.state.popover_trade.base_amount, 7)}</b> { getBaseAsset(this.state.popover_trade) } to {this.checkMyAccount(this.state.popover_trade.counter_account) ? 'My Account' : ''} {shortAddress(this.state.popover_trade.counter_account)}</span>
                              }
                              <br/>
                              <small className="text-secondary" style={{marginTop: 6, display: 'block'}}>Price: <b className={this.state.popover_trade.base_is_seller ? 'text-success' : 'text-danger'}>{this.state.popover_price}</b> / {formatDate(this.state.popover_trade.ledger_close_time)}</small>
                            </PopoverBody>
                        </Popover>
                      :
                        null
                  }



                  {/*<Table responsive hover className="text-left table-body">

                      <tbody style={{fontSize: 10}}>
                        {
                          this.state.trades ?

                            this.state.trades.map((item, index) => {
                              return (
                                <tr key={index} id={`popover-${index}`} className={`${item.base_is_seller ? 'text-success' : 'text-danger'}`} onClick={() => {this.togglePopover(item, index)}}>
                                    <td>{numFormat((item.price.d / item.price.n), 6)}</td>
                                    <td>{numFormat(item.counter_amount, 6)}</td>
                                    <td>{numFormat(item.base_amount, 6)}</td>
                                    <td>
                                      <small>{format(item.ledger_close_time, 'en_US')}</small>
                                    </td>
                                </tr>
                              )
                            })
                          : null
                        }
                      </tbody>
                    </Table>*/}
              </CardBody>
            </Card>

          </Col>
        </Row>



        <Row className="wr-row">

          {/*<Col md={3} lg={3} className="trade-col">
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Issuer (Toml)</h5>
                </div>
                <div className="panel__btns">
                  <button className="panel__btn" type="button" onClick={this.onRefresh}><AutorenewIcon /></button>
                </div>

                {this.state.refresh ? <div className="panel__refresh"><LoadingIcon /></div> : ''}

                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
                <p>asdfasdfasdfasdfsadf</p>
              </CardBody>
            </Card>
          </Col>*/}

          <Col md={12} className="trade-col right-last">
            <Card>
              <CardBody>
                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          <b>Trades</b>
                        </NavLink>
                      </NavItem>
                      {/*<NavItem>*/}
                      {/*  <NavLink*/}
                      {/*    className={classnames({ active: this.state.activeTab === '2' })}*/}
                      {/*    onClick={() => {*/}
                      {/*      this.toggle('2');*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <b>Payments</b>*/}
                      {/*  </NavLink>*/}
                      {/*</NavItem>*/}

                      {/*<NavItem>*/}
                      {/*  <NavLink*/}
                      {/*    className={classnames({ active: this.state.activeTab === '3' })}*/}
                      {/*    onClick={() => {*/}
                      {/*      this.toggle('3');*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <b>Trustlines</b>*/}
                      {/*  </NavLink>*/}
                      {/*</NavItem>*/}

                      {/*<NavItem>*/}
                      {/*  <NavLink*/}
                      {/*    className={classnames({ active: this.state.activeTab === '4' })}*/}
                      {/*    onClick={() => {*/}
                      {/*      this.toggle('4');*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <b>Offers</b>*/}
                      {/*  </NavLink>*/}
                      {/*</NavItem>*/}
                      {/*<NavItem>*/}
                      {/*  <NavLink*/}
                      {/*    className={classnames({ active: this.state.activeTab === '5' })}*/}
                      {/*    onClick={() => {*/}
                      {/*      this.toggle('5');*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <b>Markets</b>*/}
                      {/*  </NavLink>*/}
                      {/*</NavItem>*/}
                      {/*<NavItem>*/}
                      {/*  <NavLink*/}
                      {/*    className={classnames({ active: this.state.activeTab === '6' })}*/}
                      {/*    onClick={() => {*/}
                      {/*      this.toggle('6');*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <b>Holders</b>*/}
                      {/*  </NavLink>*/}
                      {/*</NavItem>*/}
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '7' })}
                          onClick={() => {
                            this.toggle('7');
                          }}
                        >
                          <b>Meta (toml)</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '8' })+' text-secondary'}
                          onClick={() => {
                            this.toggle('8');
                          }}
                        >
                          <small><b>My Offers {this.state.offers.length && this.state.account ? <span>(<span className={'text-info'}>{this.state.offers.length}</span>)</span> : null}</b></small>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '9' })+' text-secondary'}
                          onClick={() => {
                            this.toggle('9');
                          }}
                        >
                          <small><b>My Trades</b></small>
                        </NavLink>
                      </NavItem>
                      <NavItem >
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '10' })+' text-secondary'}
                          onClick={() => {
                            this.toggle('10');
                          }}
                        >
                          <small><b>My Pairs</b></small>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        {
                          this.state.trades.length ?
                            <Trades items={this.state.trades} />
                          :
                            this.state.isTrades ?
                              <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                              : <Card><CardBody><div className={'not-history'}>{this.noTradeHistory}</div></CardBody></Card>
                        }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.trades_more_btn ?
                              <Button color="secondary" onClick={() => this.getTrades()}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                  <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>

                      <TabPane tabId="2">
                        {
                          this.state.payments.length ?
                            <PaymentsForAsset items={this.state.payments}/>
                          :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                        }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.payments_more_btn ?

                              <Button color="secondary" onClick={() => this.getPayments()}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                  <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>

                      <TabPane tabId="3">

                        {
                          this.state.trustlines.length ?
                            <Trustlines items={this.state.trustlines} asset={counter_asset}/>
                          :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                        }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.trustlines_more_btn ?

                              <Button color="secondary" onClick={this.getTrustlines}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                  <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>

                      </TabPane>

                      <TabPane tabId="4">

                        {
                          this.state.asset_offers.length ?
                            <AssetOffers items={this.state.asset_offers} asset={counter_asset}/>
                            :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                        }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.asset_offers_more_btn ?

                              <Button color="secondary" onClick={this.getAssetOffers}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                    <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>

                      </TabPane>

                      <TabPane tabId="5">

                        {
                          this.state.markets.length ?
                            <Markets items={this.state.markets}
                                     assets={this.state.assets}
                                     asset={counter_asset}
                                     price={this.state.price}/>
                            :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                        }
                        {/*<br/>*/}
                        {/*<div className="text-center">*/}
                        {/*  {*/}
                        {/*    this.state.markets_more_btn ?*/}

                        {/*      <Button color="secondary" onClick={this.getMarketsForAsset}>*/}
                        {/*        {*/}
                        {/*          this.state.load_more ? 'Load more...' :*/}
                        {/*            <SpinnerLoader />*/}
                        {/*        }*/}
                        {/*      </Button>*/}
                        {/*      : null*/}
                        {/*  }*/}
                        {/*</div>*/}

                      </TabPane>

                      <TabPane tabId="6">

                        {
                          this.state.holders.length ?
                            <Holders items={this.state.holders} asset={counter_asset} supply={asset.amount}/>
                            :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                        }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.holders_more_btn ?

                              <Button color="secondary" onClick={() => this.getHolders()}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                    <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>

                      </TabPane>

                      <TabPane tabId="7">
                        {
                          this.state.asset && this.state.toml && this.state.toml_full ?
                            <Issuer asset={this.state.asset}
                                    toml={this.state.toml}
                                    toml_full={this.state.toml_full}
                                    home_domain={this.state.home_domain}/>
                          :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                        }
                      </TabPane>

                      <TabPane tabId="8">
                        {
                          this.state.offers.length && this.state.account ?
                            <Offers items={this.state.offers}
                                    account_id={this.state.select_address} />
                          :
                            (!this.state.select_address ?
                                <p>To display the data you must select Account</p>
                              :
                                !this.state.load_offers ?
                                  <Card><CardBody><br/><br/><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                                : <p>No offers</p>
                            )
                        }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.offers_more_btn ?
                              <Button color="secondary" onClick={() => this.getOffers(this.state.select_address)}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                  <SpinnerLoader />
                                }
                              </Button> : null
                          }
                        </div>
                      </TabPane>

                      <TabPane tabId="9">
                        {
                          this.state.my_trades.length ?
                            <Trades items={this.state.my_trades} />
                          :
                            (!this.state.select_address ?
                                <p>To display the data you must select Account</p>
                              :
                                <Card><CardBody><br/><br/><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                            )
                        }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.my_trades_more_btn ?
                              <Button color="secondary" onClick={() => this.getTradesForAccount(this.state.select_address)}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                  <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>

                      <TabPane tabId="10">
                        <Card>
                          <CardBody >
                            {
                              this.state.exchange_pairs ?
                                this.state.exchange_pairs.map(this.renderPair)
                                : null
                            }
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row className="wr-row">
          <Col md={12} className="">

            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={10000}
              arrows={false}
              customButtonGroup={''}
              keyBoardControl
              renderButtonGroupOutside
              renderDotsOutside={false}
              className="assets-carousel"
              infinite={true}
              >
                {
                  this.state.assets_db ?
                    this.state.assets_db.map((item, index) => {
                      return (
                        <div key={item.id} style={{opacity: 0.7}}>
                          <a href={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}>
                            <div className="wr-asset-item-icon">
                              <img className="asset-item-icon-top" src={item.image == 'undefined' || item.image == '' ? '/img/digitalcoin.png' : item.image} />
                            </div>
                            <div style={{fontSize: 12}}>
                              <div><b className="text-secondary">{item.asset_code}</b> <small className={`text-${item.change_24h_color}`}>{item.change24}%</small></div>
                              <div className="asset-domain">{item.home_domain}</div>
                            </div>
                          </a>
                        </div>
                      )
                    })
                  : null
                }
            </Carousel>

          </Col>
        </Row>


        <Footer />

      </Container>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    theme: state.theme
  }
}

export default connect(mapStateToProps)(translate('common')(reduxForm({ form: 'trade_form' })(Trade)));
